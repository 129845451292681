import * as moment from 'moment';
import { SegmentedMessage } from 'sms-segments-calculator';
import { BLOCKED_KEYWORDS } from './constants';
import { formatPhoneNumber } from '../../../Component/Analytics/utils';

const MAX_CHARACTER_LIMIT = {
  'UCS-2': 670,
  'GSM-7': 1530,
};

export const calculateSmsCharacterLength = message => {
  if (!message) return 0;
  const segmentedMessage = new SegmentedMessage(message);
  return segmentedMessage.numberOfUnicodeScalars;
};

export const calculateNumOfSmsTextMessages = message => {
  const segmentedMessage = new SegmentedMessage(message);

  return segmentedMessage.segmentsCount;
};

export const calculateSmsMaxLength = message => {
  const segmentedMessage = new SegmentedMessage(message);

  return MAX_CHARACTER_LIMIT[segmentedMessage.encodingName];
};

export const getFullNameOrPhoneNumber = message => {
  if (message.user && (message.user.firstName || message.user.lastName)) {
    return `${message.user.firstName || ''} ${message.user.lastName || ''}`;
  } else if (message.firstName || message.lastName) {
    return `${message.firstName || ''} ${message.lastName || ''}`;
  } else {
    if (message.contactPhone.length === 10) {
      return formatPhoneNumber(message.contactPhone);
    }
    return message.contactPhone;
  }
};

export const getContactFullNameOrPhoneNumber = message => {
  if (message?.contact?.firstName || message?.contact?.lastName) {
    return `${message.contact.firstName || ''} ${
      message.contact.lastName || ''
    }`;
  } else {
    if (message.contactPhone.length === 10) {
      return formatPhoneNumber(message.contactPhone);
    }
    return message.contactPhone;
  }
};

export const checkIfValidPhoneNumber = val => {
  return !!val.match(/[0-9]+/);
};

export const escapeRegExp = value => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const capitalizeFirstLetter = word => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const formatDate = timestamp => {
  return moment(timestamp).format('MM/DD/YYYY');
};

export const formatTime = timestamp => {
  return moment(timestamp).format('h:mm a');
};

export const monthsPassed = date => {
  const specificDate = moment(date);
  const currentDate = moment();

  return currentDate.diff(specificDate, 'months');
};

export const isBlockedKeyword = keyword => {
  return BLOCKED_KEYWORDS.some(
    blockedWord => blockedWord.toLowerCase() === keyword.trim().toLowerCase()
  );
};

export const isUserActionAllowed = ({ role, userPrivilages }) => {
  //All roles in order from high to low
  const rolesMap = {
    'Super Admin': 'isSA',
    'Super User': 'isSU',
    'In House Testing': 'isIHT',
    'Executive Admin': 'manageUser_Exec',
    'Staff Admin': 'manageUser_Admin',
    'Staff Editor': 'isSE',
    'Staff Support': 'isSS',
  };

  const rolesMapReversed = {
    isSA: 'Super Admin',
    isSU: 'Super User',
    isIHT: 'In House Testing',
    manageUser_Exec: 'Executive Admin',
    manageUser_Admin: 'Staff Admin',
    isSE: 'Staff Editor',
    isSS: 'Staff Support',
  };

  const roles = [
    //All roles in order from high to low
    'Super Admin',
    'Super User',
    'In House Testing',
    'Executive Admin',
    'Staff Admin',
    'Staff Editor',
    'Staff Support',
  ];

  if (
    userPrivilages[rolesMap[role]] ||
    roles.indexOf(rolesMapReversed[userPrivilages[0]]) <= roles.indexOf(role)
  ) {
    return true;
  }

  return false;
};

export const truncateFullNameIfNeeded = (firstName, lastName) => {
  const maxLength = 20;

  // Handle null, undefined, or non-string inputs by converting them to empty strings
  firstName = typeof firstName === 'string' ? firstName : '';
  lastName = typeof lastName === 'string' ? lastName : '';

  // If the first name alone is longer than maxLength, truncate it
  if (firstName.length > maxLength) {
    return firstName.slice(0, maxLength) + '...';
  }

  // Calculate remaining space for the last name
  const remainingSpace = maxLength - firstName.length;

  // If there's no space left for the last name, return only the first name
  if (remainingSpace <= 0) {
    return firstName;
  }

  // If the last name is empty, return only the first name
  if (!lastName) {
    return firstName;
  }

  // If the first name is empty, return only the last name
  if (!firstName) {
    return lastName;
  }

  // If the last name fits within the remaining space, return the full name
  if (lastName.length <= remainingSpace) {
    return `${firstName} ${lastName}`;
  }

  // If the last name is too long, truncate it
  return `${firstName} ${lastName.slice(0, remainingSpace)}...`;
};
