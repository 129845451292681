import styled from 'styled-components';

export const Header = styled.div`
  background-color: #005092;
  color: white;
  padding: 10px;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  min-width: 500px;
  height: 100%;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: auto;
  font-family: Montserrat;
`;

export const Title = styled.div`
  font-size: 16px;
  display: flex;
  gap: 10px;
  color: #007ade;
  font-weight: bold;
`;

export const Text = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

export const Note = styled.span`
  font-size: 12px;
  color: #777777;
  margin-top: -10px;
`;

export const Bold = styled.span`
  color: #007ade;
  font-weight: 600;
`;

export const BoldText = styled.span`
  font-weight: 550;
  font-family: Montserrat;
  padding-right: 5px;
`;

export const SecondaryText = styled.span`
  color: #777777;
`;

export const Space = styled.div`
  height: 5px;
`;

export const Button = styled.button`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border-radius: 25px;
  text-align: center;
  color: #007ade;
  border: 2px solid #007ade;
  transition: all 0.3s ease-in;
  background-color: white;
  width: 110px;
  height: 40px;
  cursor: pointer;
  &:hover,
  &.active {
    color: white;
    background-color: #007ade;
    border: 2px solid #007ade;
  }
  &:disabled {
    color: #737373;
    border-color: #737373;
    background-color: white;
    cursor: not-allowed;
  }
`;

export const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const FixedWidthColumn = styled.div`
  width: 135px;
`;

export const Truncate = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 160px);
`;
