import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import {
  loadMembers,
  removeMember,
  selectMember,
} from '../../../../../../../redux/action';
import { REMOVE } from '../../../../constants';
import generateActionBtns from '../_shared/generateActionBtns';
import { getMembers } from '../../../_shared/getMembers';

const ContactsTable = ({
  members,
  selectedMembers,
  selectedTopicsList,
  previousSelectedTopicsList,
  orgId,
  actions,
}) => {
  const [loadingContacts, setLoadingContacts] = useState(false);

  const loadMembers = async () => {
    setLoadingContacts(true);
    const { emailRecipients, textRecipients } = await getMembers(
      { selectedTopicsList },
      orgId,
      { contactTab: true },
      true
    );
    actions.loadMembers([...emailRecipients, ...textRecipients]);
    setLoadingContacts(false);
  };

  useEffect(() => {
    if (
      !members.length ||
      JSON.stringify(selectedTopicsList.sort()) !==
        JSON.stringify(previousSelectedTopicsList.sort())
    ) {
      loadMembers();
    }
  }, [selectedTopicsList]);

  const handleMemberSelect = ({ id, firstName, lastName }, action) => {
    if (action === REMOVE) {
      actions.removeMember(id);
    } else {
      actions.selectMember({
        [id]: { action, name: `${firstName} ${lastName}` },
      });
    }
  };

  const columns = ['First Name', 'Last Name', 'Phone', 'Email'];
  const rows = ['firstName', 'lastName', 'phoneNumber', 'email'];
  const flex = [1, 1, 1, 1];

  const generatedColumns = [
    generateActionBtns(handleMemberSelect, selectedMembers, false),
  ];

  generatedColumns.push(
    ...columns.map((text, index) => ({
      field: rows[index],
      headerName: text,
      flex: flex[index],
    }))
  );

  const generatedRows = members.map(row => {
    const generatedRow = { id: row._id };
    rows.forEach(field => {
      generatedRow[field] = row[field];
    });
    return generatedRow;
  });

  return loadingContacts ? (
    <CircularProgress style={{ margin: 'auto', color: '#007ADE' }} />
  ) : (
    <DataGrid
      rows={generatedRows}
      columns={generatedColumns}
      pageSize={15}
      rowsPerPageOptions={[15]}
      selectionModel={Object.keys(selectedMembers)}
    />
  );
};

const mapStateToProps = state => ({
  members: state.audience.members.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  ),
  selectedMembers: state.audience.selectedMembers,
  selectedTopicsList: state.audience.selectedTopicsList,
  previousSelectedTopicsList: state.audience.optimizations.selectedTopicsList,
  orgId: state.organization.selected.id,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { loadMembers, removeMember, selectMember },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsTable);
