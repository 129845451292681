import React from 'react';
import { TextContainer, BoldText } from './EmptyConversation.styled';

const DeliveryIssuesMessage = ({ newContact }) => {
  return (
    <TextContainer>
      Sorry, a new conversation couldn&apos;t be started with{' '}
      {newContact.firstName} {newContact.lastName} for the following reason:
      <BoldText>
        This contact&apos;s phone number does not appear to be receiving text
        messages.
      </BoldText>
      You&apos;ll need to make sure the contact has a working mobile number in
      order to start a text conversation with them.
    </TextContainer>
  );
};

export default DeliveryIssuesMessage;
