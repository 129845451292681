import React from 'react';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  PopupContainer,
  PartyIcon,
  TextContainer,
  Title,
  Subtitle,
  CancelButton,
} from './SavedRowsAnnouncement.styled';
import { ReactComponent as PartyIconSvg } from './party-icon.svg';

const SavedRowsAnnouncement = ({ onClose }) => {
  const handleClick = () => {
    window.open(
      'https://www.loom.com/embed/fa7f7ca59b93454bb6a327fdae7f05f7?sid=d71eefb7-0243-46f3-ba6a-669aeb6f7750',
      '_blank'
    );
  };

  return (
    <PopupContainer>
      <PartyIcon>
        <PartyIconSvg />
      </PartyIcon>
      <TextContainer onClick={handleClick}>
        <Title>Saved Rows are here!</Title>
        <Subtitle>Click to learn more...</Subtitle>
      </TextContainer>
      <CancelButton onClick={onClose}>
        <CancelOutlinedIcon />
      </CancelButton>
    </PopupContainer>
  );
};

export default SavedRowsAnnouncement;
