import React from 'react';
import { CircularProgress, Box, Typography } from '@material-ui/core';

const LoadingPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <CircularProgress
        size={60}
        thickness={4}
        style={{ marginBottom: '10px', color: '#007ADE' }}
      />

      <Typography variant="h7" color="textPrimary" mt={2}>
        Loading...
      </Typography>
    </Box>
  );
};

export default LoadingPage;
