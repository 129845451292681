import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  color: #1e1e1e;
  width: 500px;
  padding: 20px 16px 10px;
  font-family: Lato;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
`;

export const Subtitle = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 12px;
  font-family: Lato;
`;

export const Body = styled.div`
  padding: 16px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-top: 1px solid #e9e9e9;
`;

export const Button = styled(MuiButton)`
  && {
    padding-left: 20px;
    padding-right: 20px;
    border: none;
    min-width: 110px;
  }
`;

export const PrimaryButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: ${({ isDelete }) => (isDelete ? '#ff6161' : '#0076ff')};
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  border-radius: 25px;
  border: 2px solid ${({ isDelete }) => (isDelete ? '#ff6161' : '#0076ff')};
  height: 35px;
  padding: 0 20px 0 15px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: ${({ isDelete }) => (isDelete ? '#ff6161' : '#0076ff')};
  }
  &:disabled {
    background-color: white;
    color: #8d8d8d;
    border: 2px solid #8d8d8d;
    cursor: default;
  }
`;

export const SecondaryButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: #8d8d8d;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: white;
  border-radius: 25px;
  border: 2px solid #8d8d8d;
  height: 35px;
  padding: 0 20px 0 15px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: #8d8d8d;
  }
`;
