import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toast, fullloader } from '../../../redux/action';
import {
  saveNewTemplate as saveNewTemplateService,
  updateTemplate,
  getTemplateBy_id,
  updateHTMLbasedOnTemplateId,
} from '../../../redux/services';
import useSaveStatus from '../Components/SavingStatus';
import {
  selectedOrganizationSelector,
  userDetailSelector,
} from '../../../redux/selector';
import { TemplateHeader } from '../Components/Header';
import BaseEditor from '../BaseEditor';

const TemplateEditor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const templateId = queryString.parse(location.search).template;

  const [
    saveStatus,
    isTextStatusSaved,
    { onStartSavingStatus, onFinishSavingStatus, onMarkChangesUnsavedStatus },
  ] = useSaveStatus();

  const organizationId = useSelector(selectedOrganizationSelector).id;
  const userId = useSelector(userDetailSelector).id;

  const [design, setDesign] = useState(false);
  const [html, setHtml] = useState('');
  const [template, setTemplate] = useState({});

  const updateTemplateBasedOnId = async shouldLoadSavedTemplatesPage => {
    await updateHTMLbasedOnTemplateId(templateId);
    if (shouldLoadSavedTemplatesPage) {
      history.push('/dashboard/drafts?tab=savedTemplates'); // Navigate to the desired page html is updated
    }
  };

  // Detect route changes and trigger updateTemplateBasedOnId function
  useEffect(() => {
    const unlisten = history.listen(newLocation => {
      const queryParams = new URLSearchParams(newLocation.search);
      const tab = queryParams.get('tab'); // Get the value of the `tab` parameter
      // Check if the user is navigating away from the current page to /dashboard/drafts?tab=savedTemplates
      if (
        newLocation.pathname == '/dashboard/drafts' &&
        tab === 'savedTemplates'
      ) {
        // update html based on template _id
        updateTemplateBasedOnId(true);

        history.replace('/loading');
      } else {
        updateTemplateBasedOnId();
      }
    });

    return () => {
      unlisten(); // Cleanup the listener
    };
  }, [history, location, templateId]);

  const loadTemplate = async () => {
    try {
      dispatch(fullloader('true', 'Loading template.'));
      const fetchedTemplate = await getTemplateBy_id(templateId);

      setTemplate(fetchedTemplate);

      const parsedDesign =
        typeof fetchedTemplate.design === 'string'
          ? JSON.parse(fetchedTemplate.design)
          : fetchedTemplate.design;
      setDesign(parsedDesign || {});

      if (fetchedTemplate.html) {
        setHtml(fetchedTemplate.html);
      }

      dispatch(fullloader(false));
      dispatch(toast('success', `Loaded ${fetchedTemplate.name}`));
    } catch (e) {
      console.error('Error fetching template', e);
      dispatch(toast('error', 'Error fetching template'));
      history.replace('/dashboard/drafts');
    }
  };

  const saveNewTemplate = async data => {
    try {
      const result = await saveNewTemplateService({
        archived: false,
        design: '{}',
        organizationId,
        userId,
        ...data,
      });
      history.replace(`/dashboard/sendmessage?template=${result._id}`);

      setTemplate(result);
      dispatch(toast('success', 'Loaded a new template'));
    } catch (e) {
      console.error('Error while saving a new template', e);
      dispatch(toast('error', 'Error while saving a new template'));
    }
  };

  const handleTemplateSave = async data => {
    onStartSavingStatus();
    if (templateId === 'new') {
      await saveNewTemplate(data);
    } else {
      await updateTemplate({ _id: templateId, ...data });
    }
    onFinishSavingStatus();
  };

  useEffect(() => {
    if (templateId === 'new') {
      setDesign({});
    } else {
      loadTemplate();
    }
  }, []);

  return (
    <BaseEditor
      Header={TemplateHeader}
      data={template}
      onSave={handleTemplateSave}
      saveStatus={saveStatus}
      isTextStatusSaved={isTextStatusSaved}
      onUnsavedStatus={onMarkChangesUnsavedStatus}
      design={design}
      setDesign={setDesign}
      html={html}
      setHtml={setHtml}
    />
  );
};

export default TemplateEditor;
