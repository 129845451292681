import styled from 'styled-components';
import { Edit } from '@material-ui/icons';

export const EditIcon = styled(Edit)`
  && {
    font-size: 90px;
    color: #007ade;
  }
`;

export const TemplateName = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-top: -20px;
`;
