import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addFilter,
  removeFilter,
  toast,
  useToast,
} from '../../../../../../../../../redux/action';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '..//_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Select } from 'antd';
import { ErrorMsg } from './committeeMember.styled';
import SelectStyled from '../_shared/select.styled';
import ButtonStyled from '../_shared/button';

export const CommitteeMember = ({
  checked,
  selectedFilters = [],
  filterName,
}) => {
  const [selectedMembership, setSelectedMembership] = useState({});
  const [committee, setCommittee] = useState({});
  const [year, setYear] = useState(new Date());
  const [selectedCommittees, setCommittees] = useState(selectedFilters ?? []);

  const dispatch = useDispatch();
  const { memberships, committees, orgTypeName } = useSelector(state => ({
    memberships: state.organization.selected.memberships || [],
    committees: state.committees.committees,
    orgTypeName: state.audience.orgTypeName,
  }));

  const { errorToast } = useToast();

  const committeeOptions = useMemo(() => {
    setCommittee({});
    return committees?.filter(
      committee => committee.membershipId === selectedMembership.amsId
    );
  }, [selectedMembership, committees]);

  const membershipOrAssociation = useMemo(
    () => (orgTypeName === 'magic' ? 'Association' : 'Membership'),
    [orgTypeName]
  );

  useEffect(() => {
    if (selectedCommittees.length) {
      dispatch(addFilter({ [filterName]: selectedCommittees }));
    } else if (!selectedCommittees?.length && selectedFilters?.length) {
      dispatch(removeFilter(filterName));
    }
  }, [selectedCommittees]);

  useEffect(() => {
    if (!checked && selectedCommittees.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleMembershipChange = ({ target }) => {
    const membershipName = target.value;
    const matchingMembership = memberships.find(
      mem => mem.name.trim() === membershipName.trim()
    );
    if (!matchingMembership) {
      dispatch(toast('error', 'Error selecting Membership.'));
      return;
    }
    setSelectedMembership(matchingMembership);
  };

  const handleCommitteeChange = value => {
    const selectedCommittee = committees.find(committee => {
      const comparator =
        orgTypeName === 'magic'
          ? committee.committeeDescription
          : committee.committeeId;
      return comparator === value;
    });
    setCommittee({
      id: selectedCommittee.committeeId,
      description: selectedCommittee.committeeDescription || '',
    });
  };

  const handleYearChange = target => setYear(target);

  const addCommittee = () => {
    const errorMessage = [];
    if (!Object.keys(selectedMembership).length) {
      errorMessage.push('Please select Membership.');
    }
    if (!committee?.id) {
      errorMessage.push('Please select Committee.');
    }
    if (!year) {
      errorMessage.push('Please select committee year.');
    }
    if (errorMessage.length) {
      errorToast(errorMessage.join('\n'));
    } else {
      const selectedYear = new Date(year).getFullYear();
      if (typeof committee.id === 'boolean') {
        setCommittees(committees => {
          const filteredCommittees = committees?.filter(
            committee =>
              !(
                committee.membership === selectedMembership.amsId &&
                committee.year === selectedYear
              )
          );
          filteredCommittees.push({
            membership: selectedMembership.amsId,
            committee: committee.id,
            year: selectedYear,
          });
          return filteredCommittees;
        });
      } else {
        setCommittees(committees => {
          const filteredCommittees = committees?.filter(
            committee =>
              !(
                committee.membership === selectedMembership.amsId &&
                committee.committee &&
                committee.year === selectedYear
              )
          );
          filteredCommittees.push({
            membership: selectedMembership.amsId,
            committee: committee.id,
            year: selectedYear,
          });
          return filteredCommittees;
        });
      }
      setCommittee({});
      setSelectedMembership({});
      setYear(new Date());
    }
  };

  const handleRemoveCommittee = index => {
    setCommittees(committees => [
      ...committees.slice(0, index),
      ...committees.slice(index + 1),
    ]);
  };

  const handleCheck = ({ target }) => {
    setCommittee({ id: target.checked, description: 'Any/All' });
  };

  const getSelectedCommittee = committee => {
    if (typeof committee === 'boolean') {
      return <Bold>Any/all</Bold>;
    }
    const selectedCommittee = committees.find(
      com => committee === com.committeeId
    );
    return (
      <Bold>
        {orgTypeName === 'magic'
          ? selectedCommittee?.committeeDescription
          : selectedCommittee?.committeeId}
      </Bold>
    );
  };

  const getMembershipName = membershipId => {
    const matchingMembership = memberships.find(
      mem => mem.amsId === membershipId
    );
    return matchingMembership?.name || membershipId;
  };

  return (
    <div>
      <Container>
        {selectedFilters.map(({ membership, committee, year }, index) => (
          <Wrap key={`${membership}-${index}`}>
            <FitContent>
              <Space> {index > 0 ? 'or' : '-'}</Space>
              {membershipOrAssociation}
            </FitContent>
            <Bold>{getMembershipName(membership)}</Bold>
            <FitContent>Committee </FitContent>
            {getSelectedCommittee(committee)}
            <FitContent>in </FitContent>
            <Bold>{year}</Bold>

            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveCommittee(index)}
            />
          </Wrap>
        ))}
        <Content>
          <Text>
            {selectedCommittees.length ? 'or ' : ''}
            {membershipOrAssociation}
          </Text>
          <SelectDropDown
            value={selectedMembership.name || ''}
            menuItemNames={memberships.map(mem => mem.name)}
            onChange={handleMembershipChange}
          />
        </Content>
        <Content>
          <Text isDisabled={!committeeOptions?.length}>Committee </Text>
          <SelectStyled
            value={
              orgTypeName === 'magic'
                ? committee?.description
                : committee?.committeeId
            }
            onChange={handleCommitteeChange}
            disabled={
              !selectedMembership.name ||
              !committeeOptions?.length ||
              (typeof committee?.id === 'boolean' && committee?.id)
            }
            showSearch
            filterOption={(input, option) =>
              option?.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {committeeOptions?.map(committee => (
              <Select.Option
                key={committee.committeeId}
                value={
                  orgTypeName === 'magic'
                    ? committee.committeeDescription
                    : committee.committeeId
                }
              >
                {`${committee.committeeId}${
                  orgTypeName === 'magic'
                    ? `- ${committee.committeeDescription}`
                    : ''
                }`}
              </Select.Option>
            ))}
          </SelectStyled>

          <Text isDisabled={!committeeOptions?.length}>{'or '}</Text>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={typeof committee?.id === 'boolean' && committee?.id}
                onChange={handleCheck}
                label="Any/all"
                disabled={!committeeOptions?.length || !selectedMembership}
              />
            }
            label="Any/all"
          />
        </Content>
        <Content>
          <Text isDisabled={!committeeOptions?.length}>in </Text>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: '100px', top: '-4px' }}
              disableToolbar
              variant="inline"
              margin="normal"
              autoOk
              views={['year']}
              format="yyyy"
              placeholder="yyyy"
              value={year}
              onChange={handleYearChange}
              animateYearScrolling
              allowKeyboardControl
              disabled={!committeeOptions?.length}
              minDate={
                new Date(new Date().setFullYear(new Date().getFullYear() - 5))
              }
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
            />
          </MuiPickersUtilsProvider>
        </Content>
        <ButtonStyled
          variant="contained"
          size="small"
          startIcon={<AddCircleOutline />}
          disabled={!committeeOptions?.length}
          onClick={addCommittee}
        >
          Add
        </ButtonStyled>
      </Container>
      {!committeeOptions?.length && selectedMembership?.name && (
        <ErrorMsg>No committees found for {selectedMembership.name}</ErrorMsg>
      )}
    </div>
  );
};
