import './index.css';
import 'antd/dist/antd.css';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';

import { queryClient } from './infrastructure/queries';
import { setupCssAdapter } from './infrastructure/ui/theming';
import { FeatureToggler } from './libs/feature-toggles';
import { Provider as FeatureTogglerProvider } from './libs/feature-toggles/react';
import App from './App';
import ErrorBoundary from './Pages/ErrorPage';
import { configureAllFeatureToggles } from './utils/configureFeatureToggles';
import { initializeApp } from 'firebase/app';
import { config } from './firebaseConfig';

setupCssAdapter();
initializeApp(config);

const featureToggler = new FeatureToggler();
configureAllFeatureToggles(featureToggler);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  denyUrls: ['http://localhost:3000'], //So that our dev env's dont throw unneeded errors
  integrations: [new Integrations.BrowserTracing()],
  ignoreErrors: [
    'ResizeObserver loop limit exceeded', //Ignore error that doesn't affect anything as far as we know
  ],
  tracesSampleRate: 0.25, //Reduces performance metric logging to 25%
});

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <FeatureTogglerProvider featureToggler={featureToggler}>
        <App />
      </FeatureTogglerProvider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
