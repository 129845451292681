import React, { useState, useEffect } from 'react';
import { Close } from '@material-ui/icons';
import {
  SwipeableDrawer,
  IconButton,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { WechatOutlined } from '@ant-design/icons';
import {
  Container,
  Header,
  Content,
  Title,
  Text,
  Note,
  Bold,
  Space,
  Button,
} from './SendTextDrawer.styled';
import ContactSelector from './ContactSelector';
import {
  fetchTextMessagesByNoteRouterId,
  checkPreviousReplyStatus,
} from '../api';
import TextMessagesDrawer from '../TextMessagesDrawer';
import { useToast } from '../../../redux/action';

const SendTextDrawer = ({ onClose, isDrawerOpen, onOpen, setTextMessages }) => {
  const { errorToast } = useToast();
  const [isTextMessageDrawerVisible, setTextMessageDrawerVisibility] =
    useState(false);
  const [isTextMessageEmptyDrawerVisible, setTextMessageEmptyDrawerVisibility] =
    useState(false);
  const [isStopTextMessageDrawerVisible, setStopTextMessageDrawerVisibility] =
    useState(false);
  const [
    isUndeliveredTextMessageDrawerVisible,
    setUndeliveredTextMessagDrawerVisibility,
  ] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [error, setError] = useState(false);
  const [isPhoneNumberInputVisible, setPhoneNumberInputVisibility] =
    useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedContactConversations, setSelectedContactConversations] =
    useState([]);
  const [latestMessage, setLatestMessage] = useState(null);

  useEffect(() => {
    if (selectedContactConversations.length) {
      setTextMessageDrawerVisibility(true);
    }
  }, [selectedContactConversations]);

  const formatPhoneNumber = value => {
    if (!value) return value;
    const cleaned = value.replace(/\D/g, ''); // Remove non-digits
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/); // Split into groups
    if (match) {
      return !match[2]
        ? `${match[1]}` // Format as xxx
        : `${match[1]}-${match[2]}${match[3] ? `-${match[3]}` : ''}`; // Format as xxx-xxx-xxxx
    }
    return value;
  };

  useEffect(() => {
    const fetchConversationByNoteRouterIdIfExists = async () => {
      try {
        const { messages: contactConversations, message } =
          await fetchTextMessagesByNoteRouterId(selectedContact.value);

        if (contactConversations?.length) {
          setSelectedContactConversations(contactConversations);
          setLatestMessage(message);
        } else if (selectedContact.phoneNumber) {
          setTextMessageEmptyDrawerVisibility(true);
        }
      } catch (e) {
        console.error('Error in fetchConversationByNoteRouterIdIfExists:', e);
        errorToast(
          'Encountered an error while fetching conversations by noteRouterId.'
        );
      }
    };

    const handleCheckPreviousReplyStatus = async () => {
      try {
        const { repliedStop, hasDeliveryIssue } =
          await checkPreviousReplyStatus({
            noteRouterId: selectedContact.value,
            contactPhone: phoneNumber,
          });

        if (repliedStop) {
          setStopTextMessageDrawerVisibility(true);
        } else if (hasDeliveryIssue) {
          setUndeliveredTextMessagDrawerVisibility(true);
        } else {
          fetchConversationByNoteRouterIdIfExists();
        }
      } catch (e) {
        console.error('Error in handleCheckPreviousReplyStatus:', e);
        errorToast(
          'Encountered an error while checking previous message delivery status.'
        );
      }
    };

    if (!selectedContact) {
      setError(null);
    } else {
      handleCheckPreviousReplyStatus();
    }
  }, [selectedContact]);

  const validatePhoneNumber = value => {
    const regex = /^\d{10}$/;
    return regex.test(value);
  };

  const handleChange = event => {
    const value = event.target.value.replace(/\D/g, ''); // Remove non-digits
    if (value.length <= 10) {
      setPhoneNumber(formatPhoneNumber(value)); // Format the phone number
      setError(!validatePhoneNumber(value));
    }
  };

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        <Container>
          <Header>
            <span>
              <IconButton onClick={onClose}>
                <Close style={{ color: 'white' }} />
              </IconButton>
              New Text Conversation
            </span>
          </Header>
          <Content>
            <Title>
              <WechatOutlined style={{ color: '#007ADE', fontSize: '24px' }} />
              Conversation with...
            </Title>
            <Text>Select a contact to text:</Text>
            <Note>You can search by name, phone number, or member ID.</Note>
            <ContactSelector
              setPhoneNumberInputVisibility={setPhoneNumberInputVisibility}
              setSelectedContact={setSelectedContact}
              selectedContact={selectedContact}
              setPhoneNumber={setPhoneNumber}
            />
            {isPhoneNumberInputVisible && selectedContact && (
              <>
                <Space />
                <Text>
                  Hm, you don&rsquo;t appear to have a mobile number on file for
                  <Bold>
                    {' '}
                    {selectedContact.firstName} {selectedContact.lastName}
                  </Bold>
                  . If you know their number, enter it below:
                </Text>
                <Space />
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  size="small"
                  style={{ width: '50%' }}
                  value={phoneNumber || ''}
                  onChange={handleChange}
                  error={error}
                  inputProps={{
                    maxLength: 12, // Allow for dashes (xxx-xxx-xxxx)
                    style: {
                      color: error ? '#FF6161' : 'inherit', // Change text color if error
                    },
                  }}
                />
                <FormHelperText
                  style={{
                    height: '20px',
                    color: '#FF6161',
                    visibility: error ? 'visible' : 'hidden',
                  }}
                >
                  {error ? 'Error - Invalid Phone Number' : ' '}
                </FormHelperText>
                <Button
                  onClick={() => {
                    setTextMessageEmptyDrawerVisibility(true);
                  }}
                  disabled={error || !phoneNumber}
                >
                  Continue
                </Button>
              </>
            )}
          </Content>
        </Container>
      </SwipeableDrawer>
      {/* if there is a conversation history with current contact */}
      <SwipeableDrawer
        anchor="right"
        open={isTextMessageDrawerVisible}
        onClose={() => {
          setTextMessageDrawerVisibility(false);
          onClose();
        }}
        onOpen={() => setTextMessageDrawerVisibility(true)}
      >
        <TextMessagesDrawer
          onClose={() => {
            setTextMessageDrawerVisibility(false);
            onClose();
          }}
          conversationMessages={selectedContactConversations}
          message={latestMessage}
          setTextMessages={setTextMessages}
          isDrawerOpen={isTextMessageDrawerVisible}
        />
      </SwipeableDrawer>
      {/* if there is no conversation history with current contact */}
      <SwipeableDrawer
        anchor="right"
        open={isTextMessageEmptyDrawerVisible}
        onClose={() => {
          setTextMessageEmptyDrawerVisibility(false);
          onClose();
        }}
        onOpen={() => setTextMessageEmptyDrawerVisibility(true)}
      >
        <TextMessagesDrawer
          onClose={() => {
            setTextMessageEmptyDrawerVisibility(false);
            onClose();
          }}
          setTextMessages={setTextMessages}
          isDrawerOpen={isTextMessageEmptyDrawerVisible}
          newContact={{
            ...selectedContact,
            contactPhone: phoneNumber || selectedContact?.phoneNumber,
          }}
        />
      </SwipeableDrawer>
      {/* if a contact replied stop previosly */}
      <SwipeableDrawer
        anchor="right"
        open={isStopTextMessageDrawerVisible}
        onClose={() => {
          setStopTextMessageDrawerVisibility(false);
          onClose();
        }}
        onOpen={() => setStopTextMessageDrawerVisibility(true)}
      >
        <TextMessagesDrawer
          onClose={() => {
            setStopTextMessageDrawerVisibility(false);
            onClose();
          }}
          isDrawerOpen={isStopTextMessageDrawerVisible}
          newContact={{
            ...selectedContact,
            contactPhone: phoneNumber || selectedContact?.phoneNumber,
          }}
          repliedStopPreviously={true}
        />
      </SwipeableDrawer>
      {/* if there are some delivery issues with previous text messages */}
      <SwipeableDrawer
        anchor="right"
        open={isUndeliveredTextMessageDrawerVisible}
        onClose={() => {
          setUndeliveredTextMessagDrawerVisibility(false);
          onClose();
        }}
        onOpen={() => setUndeliveredTextMessagDrawerVisibility(true)}
      >
        <TextMessagesDrawer
          onClose={() => {
            setUndeliveredTextMessagDrawerVisibility(false);
            onClose();
          }}
          isDrawerOpen={isUndeliveredTextMessageDrawerVisible}
          newContact={{
            ...selectedContact,
            contactPhone: phoneNumber || selectedContact?.phoneNumber,
          }}
          hasDeliveryIssue={true}
        />
      </SwipeableDrawer>
    </>
  );
};

export default SendTextDrawer;
