import store from '../redux/store';
import { userDetailSelector } from '../redux/selectors/user';
import { selectedOrganizationSelector } from '../redux/selectors/organization';
import Feature from '../infrastructure/features';

const FEATURE_TOGGLE_MATCHERS = {
  domain: {
    identify: config => config.startsWith('@'),
    match: (config, { user }) => {
      if (!user.email) {
        return false;
      }

      return user.email.endsWith(config);
    },
  },
  organization: {
    identify: config => config.startsWith('org:'),
    match: (config, { organization }) => organization.id === config.slice(4),
  },
  exact: {
    identify: () => true,
    match: (config, { user }) => user.email === config,
  },
};

const FEATURE_TOGGLE_MATCHERS_ORDERED_BY_PRIORITY = [
  FEATURE_TOGGLE_MATCHERS.domain,
  FEATURE_TOGGLE_MATCHERS.organization,
  FEATURE_TOGGLE_MATCHERS.exact,
];

const isFeatureEnabledFor = (config, context) => {
  return config.some(value => {
    const matcher = FEATURE_TOGGLE_MATCHERS_ORDERED_BY_PRIORITY.find(matcher =>
      matcher.identify(value)
    );
    return matcher.match(value, context);
  });
};

const configureFeatureToggle = (envVariable, feature, featureToggler) => {
  const rawFeatureToggleConfig = process.env[envVariable];
  const isEnabledForAll = rawFeatureToggleConfig === '*';

  if (isEnabledForAll) {
    featureToggler.set(feature, true);
  } else if (rawFeatureToggleConfig) {
    const featureToggleConfig = rawFeatureToggleConfig
      .split(',')
      .map(value => value.trim());

    store.observe(
      state => {
        const user = userDetailSelector(state);
        const activeOrganization = selectedOrganizationSelector(state);
        if (!user || !activeOrganization) {
          return false;
        }

        const context = { user, organization: activeOrganization };
        return isFeatureEnabledFor(featureToggleConfig, context);
      },
      isEnabled => {
        featureToggler.set(feature, isEnabled);
      }
    );
  }
};

export const configureAllFeatureToggles = featureToggler => {
  configureFeatureToggle(
    'REACT_APP_AC_USERS',
    Feature.AUTOMATED_CAMPAIGNS,
    featureToggler
  );

  configureFeatureToggle('REACT_APP_AB_USERS', Feature.AB, featureToggler);

  configureFeatureToggle(
    'REACT_APP_TEXTING_HUB_USERS',
    Feature.TEXTING_HUB,
    featureToggler
  );

  configureFeatureToggle(
    'REACT_APP_USER_INITIATED_TEXT_USERS',
    Feature.USER_INITIATED_TEXT,
    featureToggler
  );

  configureFeatureToggle(
    'REACT_APP_SAVED_ROWS_USERS',
    Feature.SAVED_ROWS,
    featureToggler
  );

  configureFeatureToggle(
    'REACT_APP_EXPORT_AC_ACTIVITY_USERS',
    Feature.EXPORT_AC_ACTIVITY,
    featureToggler
  );

  store.observe(
    state => {
      const activeOrganization = selectedOrganizationSelector(state);

      return activeOrganization.eventsEnabled || false;
    },
    isEventsAndClassesEnabled => {
      featureToggler.set(Feature.EVENTS_AND_CLASSES, isEventsAndClassesEnabled);
    }
  );
};
