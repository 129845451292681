import api from '../../utils/andromeda-api';

export async function getOrgAudiences({
  orgId,
  skip,
  limit,
  sortby,
  filter,
  searchValue,
}) {
  const { data } = await api.get(`/audiences/organization/${orgId}`, {
    params: { skip, limit, sort: sortby, filter, searchValue },
  });

  return data;
}

export async function getAudience(id) {
  const { data } = await api.get(`/audiences/${id}`);
  return data.audience;
}

export async function saveAudience(audience) {
  const { data } = await api.post('/audiences', audience);
  return data.audience;
}

export async function updateAudience(update) {
  const response = await api.put(`/audiences/${update._id}`, update);

  return response.data;
}
