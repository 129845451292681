import 'firebase/compat/auth';

import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { sendMail } from './email';
import Cookies from 'universal-cookie';
import moment from 'moment';
import api from '../../utils/andromeda-api';

const cookie = new Cookies();

/******************************** CRUD of User(login,logout,reset,forgot,etc) ************************* */
export async function getUser(userId) {
  try {
    const response = await api.get(`/users/user/${userId}`);
    console.log('Retrieved user information');
    return response.data.result;
  } catch (e) {
    console.error('Error getting user info [getUser]', e);
    return false;
  }
}

export async function getUserByAuthId(authId) {
  try {
    const response = await api.get(`/auth/authid/${authId}`);
    console.log('Retrieved user information');
    return response.data.result;
  } catch (e) {
    console.error('Error getting user info [getUserByAuthId]', e);
    return false;
  }
}

export function logoutUser(cb) {
  cookie.remove('authEmail');
  cookie.remove('fbAccess');
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      cb(true);
    })
    .catch(error => {
      console.log(error, '[logoutUser]');
      cb(false);
    });
}

export async function register(data) {
  if (!data) return;
  // Send Welcome Email
  const mail = {
    to: data.email,
    from: process.env.REACT_APP_email,
    subject:
      'Thanks for signing up for NoteRouter: Please complete the registration',
    html: `<p>Thank you for signing up with NoteRouter. Please follow the link below to complete your registration with NoteRouter.
          <a href="${process.env.REACT_APP_webUrl}/login?id=${data.authId}">${process.env.REACT_APP_webUrl}/login?id=${data.authId} </a>      
          </p> `,
  };
  const userDoesExistRes = await api.get(`/users/email/${data.email}`);
  // Return false if already exists
  if (userDoesExistRes?.data?.result) {
    // ReEnable deleted users
    const oldUser = userDoesExistRes.data.result;

    if (oldUser.delete || oldUser.enable !== 1) {
      const dataWithOldId = Object.assign({}, data, {
        id: oldUser.id,
        delete: false,
        enable: 1,
        organization: data.organization,
        organizations: data.organizations,
        updatedDate: data.updatedDate,
        authId: data.authId,
      });

      await userUpdate(dataWithOldId);
      await sendMail(mail);
      return true;
    } else {
      console.error('A user with this email already exists.');
      return false;
    }
  }
  // save the user data
  else {
    const didAddUser = await api.post('/users/addMongoUser', data);
    if (!didAddUser) return false;
    await sendMail(mail);
    return true;
  }
}

export async function createNewRegFromEmail(data) {
  const auth = getAuth();
  if (data.confirmPassword) {
    let user;
    try {
      await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      ).then(userCredentials => {
        user = userCredentials;
      });
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const { accessToken } = userCredentials.user;
      if (!accessToken) {
        throw new Error('Unable to authenticate with auth server');
      }
      setToken(data.email, accessToken);
    } catch (e) {
      console.error(e);
      return e;
    }
    const userData = await getUserByAuthId(data.authId);
    if (userData) {
      let jsonData = userData;
      jsonData.id = userData.id;
      jsonData.userId = user.user.uid;
      await api.post('/users/updateUserById', jsonData);
      return jsonData;
    } else {
      return null;
    }
  }
}

export function checkUserLoggedIn(cb) {
  const firebaseAccessToken = cookie.get('fbAccess');

  const auth = getAuth();
  onAuthStateChanged(auth, async user => {
    try {
      if (!firebaseAccessToken) {
        throw new Error('no firebase access token found');
      }
      const email = user?.email || cookie.get('authEmail');
      if (!email) {
        throw new Error('user not logged in');
      }

      const mongoUserRes = await api.post('/auth/getUserForLogin', {
        email,
        firebaseAccessToken,
      });

      const mongoUserId = mongoUserRes?.data?.result?.id;
      if (!mongoUserId) {
        throw new Error('no mongo Id found for user');
      }

      const userRes = await api.get(`/users/user/${mongoUserId}`);

      const userData = userRes?.data?.result;
      if (!userData) {
        throw new Error('no user data found');
      }

      cb(userData);
    } catch (e) {
      // remove any firebase access token and authenticated email that may exist
      cookie.remove('authEmail');
      cookie.remove('fbAccess');
      console.error('Error getting user info [checkUserLoggedIn]', e);
      cb(null);
    }
  });
}

const setToken = (email, accessToken) => {
  const cookieExpiration = moment().add(1, 'hour').toDate();
  cookie.set('authEmail', email, { expires: cookieExpiration });
  cookie.set('fbAccess', accessToken, { expires: cookieExpiration });
};

export const login = async data => {
  const auth = getAuth();
  try {
    const userCredentials = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    const { accessToken } = userCredentials.user;
    if (!accessToken) {
      throw new Error('Unable to authenticate with auth server');
    }

    // ensured that we only fetch user data from mongo if we get an accessToken from signIn API
    const mongoUserRes = await api.post('/auth/getUserForLogin', {
      email: data.email,
      firebaseAccessToken: accessToken,
    });

    const userData = mongoUserRes?.data?.result;

    if (!userData) {
      throw new Error('Cannot get user');
    }

    // user has successfully logged in and is found in db
    // so save the firebase access token as a cookie to be used for authentication elsewhere within the app
    setToken(data.email, accessToken);

    // return the userData
    return userData;
  } catch (e) {
    console.error('Error getting user info [login]', e);
    return null;
  }
};

export function forgotPassword(email, cb) {
  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
    .then(() => {
      cb(true);
    })
    .catch(() => {
      cb(false);
    });
}

export async function userDelete(ids, orgId) {
  for (let i = 0; i < ids.length; i++) {
    const ID = ids[i];
    const updateData = {
      orgId,
      id: ID,
      delete: true,
      enable: 0,
    };
    try {
      await api.post('/users/delete', updateData);
      console.log('Deleted user(s)');
    } catch (e) {
      console.error('Error deleting user(s) [userDelete]', e);
      return false;
    }
  }
  return true;
}

export async function getOrgUsers(orgId, organization) {
  if (!orgId) return [];
  const orgUserRes = await api.get(`/users/org/active/${orgId}`);
  if (orgUserRes?.data?.result) {
    let data = [];

    orgUserRes.data.result.forEach(user => {
      user.organization = organization;
      data.push(user);
    });
    return data;
  }
  return [];
}

export async function getAllOrgUsers(orgId, organization) {
  if (!orgId) return [];
  // organization parameter is there to map to users, assumes you have already called for organization
  const orgUserRes = await api.get(`/users/org/${orgId}`);
  if (orgUserRes?.data?.result) {
    let data = [];
    orgUserRes.data.result.forEach(user => {
      user.organization = organization;
      data.push(user);
    });
    return data;
  }
  return [];
}

export async function userStatus(orgId, orgUpdateObj) {
  orgUpdateObj.id = orgId;
  const updatedOrg = await api.post('/organization/update', orgUpdateObj);
  if (updatedOrg) {
    console.log('Organization status changed successfully');
  } else {
    console.log('Error updating org [userStatus]');
  }

  const userOptions = {
    orgId: orgId,
  };
  const orgUserRes = await api.get(`/users/org/active/${orgId}`, userOptions);
  if (orgUserRes?.data?.result) {
    for (const user of orgUserRes) {
      const userUpdate = {
        organization: orgUpdateObj,
        id: user.id,
      };
      const updateRes = await api.post('/users/updateUserById', userUpdate);
      if (updateRes) {
        console.log('User status changed successfully', updateRes);
      }
    }
  }
}

export async function updateUserSenderName(userData) {
  try {
    const updateRes = await api.post('/users/update/sendername', userData);
    return !!updateRes;
  } catch (e) {
    return false;
  }
}

export async function userUpdate(userData) {
  try {
    const updateRes = await api.post('/users/updateUserById', userData);
    return !!updateRes;
  } catch (e) {
    return false;
  }
}

export async function getKeyOrgContacts(orgId) {
  try {
    const { data } = await api.get(`/users/keyOrgContacts/${orgId}`);
    return data.result;
  } catch (e) {
    console.error('Error fetching key contacts. [getKeyOrgContacts]', e);
    return false;
  }
}

export async function updateKeyOrgContacts(users) {
  try {
    const { data } = await api.post('/users/keyOrgContacts', users);
    return data.result;
  } catch (e) {
    console.error(
      'Error updating/adding key contacts. [updateKeyOrgContacts]',
      e
    );
    return false;
  }
}

export async function usersTHFlagUpdate(userIds, orgId) {
  try {
    const { data } = await api.post('/users/receivesTextingHubUpdates', {
      userIds,
      orgId,
    });
    return data;
  } catch (e) {
    console.error(
      'Error updating users receivesTextingHubUpdates flag. [usersTHFlagUpdate]',
      e
    );
    return false;
  }
}
