import styled from 'styled-components';

export const Container = styled.div`
  width: 750px;
`;

export const Header = styled.div`
  color: white;
  font-size: 15px;
  height: 60px;
  width: 100%;
  background: #005092;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  position: fixed;
  z-index: 9;
`;

export const SaveBtn = styled.button`
  position: fixed;
  right: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: none;
  font-size: 14px;
  padding: 3px 20px;
  color: #005092;
  transition: all 0.3s ease-in;
  border-radius: 20px;
  &:hover,
  &.active {
    color: white;
    cursor: pointer;
    background-color: #238b22;
  }
`;

export const Group = styled.span`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Title = styled.span`
  color: white;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin: 50px 0;
  gap: 20px;
`;

export const AddBtn = styled.button`
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: #007ade;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 55%;
  gap: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Highlight = styled.span`
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border: 1px solid #007ade;
  padding: 3px 15px;
  margin-right: 5px;
  color: #007ade;
  background: #f2f8fc;
  border-radius: 20px;
  height: 30px;
  text-align: center;
  max-width: fit-content;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Text = styled.p`
  color: #707070;
  font-size: 14px;
  line-height: 17px;
  margin: -5px 0;
`;
