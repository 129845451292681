import moment from 'moment';
import api from '../../utils/andromeda-api';

export async function getDraftBy_id(draftId) {
  if (!draftId) return null;
  const response = await api.get(`/drafts/getDraftBy_id/${draftId}`);

  return response.data.result;
}

export async function getDrafts({
  orgId,
  sortby = '-saveTime',
  skip,
  limit,
  filter,
  searchValue,
}) {
  const { data } = await api.get(`/drafts/getOrgDrafts/${orgId}/${sortby}`, {
    params: { skip, limit, filter, searchValue },
  });

  return data;
}

export async function updateDraft(data) {
  if (!data._id) throw new Error('Draft id should be provided');
  await api.post('/drafts/updateDraft', {
    ...data,
    saveTime: moment().unix(),
  });
}

export async function getHTMLbaseOnDesign(design) {
  if (!design) throw new Error('Design should be provided');

  const { data } = await api.post('/drafts/getHTMLbasedOnDesign', { design });
  return data.result;
}

export async function updateHTMLbasedOnDraftId(_id) {
  if (!_id) throw new Error('Draft _id should be provided');

  await api.post('/drafts/updateHTMLbasedOnDraftId', { _id });
}

export async function copyDraft({ _id, name, newOrgId }) {
  const { data } = await api.post('/drafts/copyDraft', {
    _id,
    name,
    newOrgId,
  });

  return data.result;
}

export async function getMessageIdFromDraftId(draftId) {
  const { data } = await api.get(`/drafts/getMsgId/${draftId}`);
  return data.result;
}

export async function getMessageFromDraftId(draftId) {
  const { data } = await api.get(`/drafts/getMsg/${draftId}`);
  return data.result;
}

export async function saveNewDraftService(draft) {
  const {
    data: { result },
  } = await api.post('/drafts/saveNewDraft', {
    ...draft,
    saveTime: moment().unix(),
  });
  return result;
}

export async function mergeABdraftsService(draftId, draftBData) {
  if (!draftId) throw new Error('Missing draft id');
  if (!draftBData) throw new Error('Missing version B draft data');
  await api.post(`/drafts/mergeABdrafts/${draftId}`, draftBData);
}

export async function sendDraft(draftId, scheduleTime) {
  if (!draftId) throw new Error('Missing draft id');
  await api.post(`/drafts/sendDraft/${draftId}`, { scheduleTime });
}

export async function createVersionBDraft(draftId) {
  if (!draftId) throw new Error('Missing draft id');
  const { result } = await api.post(`/drafts/createVersionB/${draftId}`);
  return result;
}

export async function deleteVersionBDraft(draftId) {
  if (!draftId) throw new Error('Missing draft id');
  await api.delete(`/drafts/deleteVersionB/${draftId}`);
}

export async function getIdOfAnotherVersion(draftId) {
  if (!draftId) throw new Error('Missing draft id');
  const {
    data: { result },
  } = await api.get(`/drafts/getIdOfAnotherVersion/${draftId}`);
  return result;
}

export async function getAVersionId(draftId) {
  if (!draftId) throw new Error('Missing draft id');
  const {
    data: { result },
  } = await api.get(`/drafts/getAVersionId/${draftId}`);
  return result;
}

export async function validateAnotherVersionMessageInfoData(draftId) {
  if (!draftId) throw new Error('Missing draft id');
  const {
    data: { result },
  } = await api.get(`/drafts/validateABMessageInfo/${draftId}`);

  return result;
}
