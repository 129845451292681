import React, { useState } from 'react';
import { NaturePeople } from '@material-ui/icons';
import {
  KeyContactsContainer,
  Row,
  Title,
  EditBtn,
} from '../GeneralInfo.styled';
import Contacts from './Contacts';
import EditOrgContactsDrawer from '../EditOrgContactsDrawer';
import { CONTACT_LIST } from './constants';

const OrganizationContacts = ({ height, keyOrgContacts, orgId }) => {
  const [isEditOrgContactsDrawerVisible, setEditOrgContactsDrawerVisibility] =
    useState(false);
  const action = keyOrgContacts.length ? 'Edit' : 'Add';
  const keyOrgContactsObjTransformed = keyOrgContacts.reduce(
    (result, keyContact) => {
      result[keyContact.role] = keyContact;
      return result;
    },
    {}
  );

  return (
    <KeyContactsContainer height={height}>
      <Row>
        <Title>
          <NaturePeople size="small" />
          Key Contacts
        </Title>
        <EditBtn
          onClick={() => {
            setEditOrgContactsDrawerVisibility(true);
          }}
        >
          Edit
        </EditBtn>
      </Row>
      {CONTACT_LIST.map(title => (
        <Contacts
          key={title}
          title={title}
          contact={
            keyOrgContacts.filter(contact => contact.role === title)?.[0]
          }
        />
      ))}
      {isEditOrgContactsDrawerVisible && (
        <EditOrgContactsDrawer
          isEditOrgContactsDrawerVisible={isEditOrgContactsDrawerVisible}
          setEditOrgContactsDrawerVisibility={
            setEditOrgContactsDrawerVisibility
          }
          keyOrgContacts={keyOrgContacts}
          keyOrgContactsObjTransformed={keyOrgContactsObjTransformed}
          action={action}
          orgId={orgId}
        />
      )}
    </KeyContactsContainer>
  );
};

export default OrganizationContacts;
