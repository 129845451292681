import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const MembershipAndStatus = ({
  filterName,
  statuses,
  memberships,
  checked,
  selectedFilters,
  orgTypeName,
}) => {
  const dispatch = useDispatch();

  const [selectedMembership, setMembership] = useState({});
  const [selectedStatus, setStatus] = useState('');
  const [membershipAndStatus, setMembershipAndStatus] = useState(
    selectedFilters || []
  );

  useEffect(() => {
    if (membershipAndStatus.length) {
      dispatch(addFilter({ [filterName]: membershipAndStatus }));
    } else if (
      membershipAndStatus.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [membershipAndStatus]);

  useEffect(() => {
    if (!checked && membershipAndStatus.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleMembershipChange = ({ target }) => {
    const membershipName = target.value;
    const matchingMembership = memberships.find(
      mem => mem.name.trim() === membershipName.trim()
    );
    if (!matchingMembership) {
      dispatch(toast('error', 'Error selecting Membership.'));
      return;
    }
    setMembership(matchingMembership);
  };

  const handleStatusChange = ({ target }) => {
    setStatus(target.value);
  };

  const handleAddMembershipAndStatus = () => {
    const errorMessage = [];
    if (!Object.keys(selectedMembership).length) {
      errorMessage.push('Please select Membership.');
    }
    if (!selectedStatus) {
      errorMessage.push('Please select Status.');
    }
    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setMembershipAndStatus([
        ...membershipAndStatus,
        { selectedMembership: selectedMembership.amsId, selectedStatus },
      ]);
      setMembership({});
      setStatus('');
    }
  };

  const handleRemoveMembershipAndStatus = index => {
    setMembershipAndStatus([
      ...membershipAndStatus.slice(0, index),
      ...membershipAndStatus.slice(index + 1),
    ]);
  };

  const getAssociationOrMembership = () => {
    return orgTypeName === 'magic' ? 'Association' : 'Membership';
  };

  const getMembershipName = membershipId => {
    const matchingMembership = memberships.find(
      mem => mem.amsId === membershipId
    );
    return matchingMembership.name;
  };

  return (
    <Container>
      {(selectedFilters || []).map(
        ({ selectedMembership, selectedStatus }, index) => (
          <Wrap key={index}>
            <FitContent>
              {index > 0 ? <Space>or</Space> : <Space>-</Space>}{' '}
              {getAssociationOrMembership()}
            </FitContent>
            <Bold>{getMembershipName(selectedMembership)}</Bold>
            <FitContent>plus Status </FitContent>
            <Bold>{selectedStatus}</Bold>
            <CancelOutlined
              fontSize="small"
              style={{ fill: '#E63946' }}
              onClick={() => handleRemoveMembershipAndStatus(index)}
            />
          </Wrap>
        )
      )}
      <Content>
        <Text>
          {membershipAndStatus.length ? 'or' : ''}{' '}
          {getAssociationOrMembership()}
        </Text>
        <SelectDropDown
          value={selectedMembership.name || ''}
          menuItemNames={memberships.map(mem => mem.name)}
          onChange={handleMembershipChange}
        />
      </Content>
      <Content>
        <Text>plus Status</Text>
        <SelectDropDown
          value={selectedStatus}
          menuItemNames={statuses}
          onChange={handleStatusChange}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddMembershipAndStatus}
      >
        Add
      </Button>
    </Container>
  );
};

const mapStateToProps = state => ({
  statuses: state.organization.selected.assocStatus || [],
  memberships: state.organization.selected.memberships || [],
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(MembershipAndStatus);
