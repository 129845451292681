const Feature = {
  AUTOMATED_CAMPAIGNS: Symbol('automated-campaigns'),
  EVENTS_AND_CLASSES: Symbol('events-and-classes'),
  AB: Symbol('AB'),
  TEXTING_HUB: Symbol('texting-hub'),
  USER_INITIATED_TEXT: Symbol('user-initiated-text'),
  SAVED_ROWS: Symbol('saved-rows'),
  EXPORT_AC_ACTIVITY: Symbol('export-ac-activity'),
};

export default Feature;
