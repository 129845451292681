import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress, Tooltip } from '@material-ui/core';
import debounce from 'lodash/debounce';
import { fetchContactsFullNameAndIds } from '../api';
import { formatPhoneNumber } from '../../../Component/Analytics/utils';
import { truncateFullNameIfNeeded } from '../utils';
import {
  BoldText,
  SecondaryText,
  SpaceBetween,
  FixedWidthColumn,
  Truncate,
} from './SendTextDrawer.styled';

const ContactSelector = ({
  setPhoneNumberInputVisibility,
  setSelectedContact,
  setPhoneNumber,
  selectedContact,
}) => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const pageRef = useRef(0);
  const debounceTimeout = 800;
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false; // Cleanup on unmount
    };
  }, []);

  const fetchContactData = async (searchText = '') => {
    setLoading(true);

    const currentValues = searchText ? [] : contactData;
    const searchVal = searchText || searchValue;

    if (searchText) {
      setContactData([]);
      pageRef.current = 0;
    }

    try {
      const fetchedContactData = await fetchContactsFullNameAndIds({
        page: pageRef.current,
        searchValue: searchVal,
      });

      const normalizedContactData = fetchedContactData
        .filter(contact => contact.firstName && contact.noteRouterId)
        .map(transformContactData);

      if (isMounted.current) {
        setContactData([...currentValues, ...normalizedContactData]);
        setLoading(false);
        pageRef.current += 1;
      }
    } catch (err) {
      console.error(
        'Error fetching and formatting contact data for dropdown',
        err
      );
      if (isMounted.current) {
        setContactData([...contactData]);
        setLoading(false);
        pageRef.current += 1;
      }
    }
  };

  const transformContactData = contact => {
    let label = (
      <SpaceBetween>
        <Truncate>
          <BoldText>
            {truncateFullNameIfNeeded(contact.firstName, contact.lastName)}
          </BoldText>
          {contact.AMS_UID && (
            <SecondaryText> ID: {contact.AMS_UID}</SecondaryText>
          )}
        </Truncate>
        {contact.phoneNumber && contact.phoneNumber.length === 10 && (
          <FixedWidthColumn>
            {formatPhoneNumber(contact.phoneNumber)}
          </FixedWidthColumn>
        )}
      </SpaceBetween>
    );

    return {
      id: contact._id,
      value: contact.noteRouterId,
      label,
      phoneNumber: contact.phoneNumber,
      firstName: contact.firstName,
      lastName: contact.lastName,
      AMS_UID: contact.AMS_UID,
    };
  };

  const handleSearch = useMemo(() => {
    const fetchData = async value => {
      setSearchValue(value);
      await fetchContactData(value);
    };

    return debounce(fetchData, debounceTimeout);
  }, [fetchContactData, debounceTimeout]);

  const handleScroll = event => {
    const listboxNode = event.currentTarget;
    if (
      !loading &&
      listboxNode.scrollTop + listboxNode.clientHeight + 2 >=
        listboxNode.scrollHeight
    ) {
      fetchContactData();
    }
  };

  const addTooltipIfNeeded = props => {
    if (props.AMS_UID && props.AMS_UID.length > 9) {
      return (
        <Tooltip
          title={`${props.firstName} ${props.lastName}: [ID: ${props.AMS_UID}]`}
          arrow
        >
          {props.label}
        </Tooltip>
      );
    }
    return props.label;
  };

  return (
    <Autocomplete
      size="small"
      options={contactData}
      getOptionLabel={option => option.label}
      renderOption={props => addTooltipIfNeeded(props)}
      filterOptions={x => x} // Disable built-in filtering
      loading={loading}
      value={selectedContact}
      onInputChange={(event, value, reason) => {
        if (reason === 'input') {
          handleSearch(value); // Only trigger search when the user types
        }
      }}
      onChange={(event, contact) => {
        if (!contact) {
          setPhoneNumberInputVisibility(false);
        }
        setSelectedContact(contact);
        if (contact && !contact?.phoneNumber) {
          setPhoneNumberInputVisibility(true);
          setPhoneNumber(null);
        }
      }}
      onOpen={() => {
        setOpen(true);
        fetchContactData();
      }}
      onClose={() => setOpen(false)}
      open={open}
      ListboxProps={{
        onScroll: handleScroll,
      }}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={'Select a contact'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ContactSelector;
