export const isUnion = (arr1, arr2) => {
  if (!arr1 && !arr2) return [];
  if (!arr1) return [...new Set(arr2)];
  if (!arr2) return [...new Set(arr1)];

  const unionSet = new Set([...arr1, ...arr2]);
  return Array.from(unionSet);
};

// pre-supposes that data has id and name fields, returns empty object not
export const createIdNameMappingFromData = data => {
  if (!data) {
    return {};
  }
  return data.reduce((acc, curr) => {
    if (!curr.id || !curr.name) {
      return acc;
    }
    acc[curr.id] = curr.name;
    return acc;
  }, {});
};
