import React, { memo, useContext } from 'react';
import { PreferenceContext } from '../../../redux/services';
import { PortalInfoStyled } from './portalInfo.styled';

export const PortalInfo = memo(function PortalInfoComponent() {
  const { organization, member } = useContext(PreferenceContext);
  const {
    organizationName,
    acronym,
    displayPhone,
    memberPortalURL,
    customFooter,
  } = organization;

  const contactPortalUsername = member.organization?.attributes?.find(
    attr => attr.name === 'memberPortalUsername'
  )?.value;

  const updateInfoNoPortalURL = (
    <>
      This is your current info on file. To change it, please contact{' '}
      <b>{organizationName}</b> at{' '}
      <b>
        {acronym !== 'ValleyMLS' ? displayPhone : 'membership@valleymls.com'}.
      </b>
    </>
  );

  const updateInfoWithURL = (
    <>
      This is your current contact info on file. To change it, update your
      professional profile at the link below (please allow 24 hours for changes
      to appear here): <br />{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#1890ff' }}
        href={`https://${memberPortalURL}`}
      >
        {memberPortalURL}
      </a>
      {contactPortalUsername && (
        <span>
          <br /> <i> (Hint: Your username is </i>{' '}
          <span style={{ color: '#1890ff' }}>{contactPortalUsername}</span>)
        </span>
      )}
    </>
  );

  if (customFooter)
    return (
      <PortalInfoStyled dangerouslySetInnerHTML={{ __html: customFooter }} />
    );
  return (
    <PortalInfoStyled>
      {memberPortalURL ? updateInfoWithURL : updateInfoNoPortalURL}
    </PortalInfoStyled>
  );
});
