import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getCoeDate } from '../../_shared/api';
import { Box, ModalRow, Text } from './Footer.styled';
import {
  GEOGRAPHIC,
  HOME_CITY,
  HOME_COUNTY,
  HOME_ZIP_CODE,
  HOME_STATE,
  OFFICE_CITY,
  OFFICE_COUNTY,
  OFFICE_ZIP_CODE,
  OFFICE_STATE,
  DATES,
  BIRTHDATE,
  JOIN_DATE,
  ORIENTATION_DATE,
  LICENSE_EXPIRATION_DATE,
  LAST_PREFERENCE_PAGE_VISIT,
  CREDIT_CARD_EXPIRATION_DATE,
  MEMBERSHIP_STATUS,
  MEMBERSHIP_TYPE,
  BALANCE_BY_CHARGE_CODE,
  TOTAL_OF_ALL_OUTSTANDING_BALANCES,
  MEMBERSHIP_AND_BILLING_STATUS,
  MEMBER_DESIGNATIONS,
  DESIGNATION_SHOULD_EXIST,
  COMMITTEES,
  NEVER,
  EVENT_AND_CLASS_REGISTRATION,
  COURSE_REGISTRATION,
  EVENT_AND_CLASS_ATTENDANCE,
  COURSE_ATTENDANCE,
  ADDED_DATE,
  CODE_OF_ETHICS_COMPLETION,
  RAP_EMPTY_DATE,
} from '../../Content/Components/Filters/Components/_shared/constants';
import { generateMembershipMap } from '../../../../../../redux/helpers/helpers';

const FILTER_MAPPINGS = {
  [HOME_CITY]: GEOGRAPHIC,
  [HOME_COUNTY]: GEOGRAPHIC,
  [HOME_ZIP_CODE]: GEOGRAPHIC,
  [HOME_STATE]: GEOGRAPHIC,
  [OFFICE_CITY]: GEOGRAPHIC,
  [OFFICE_COUNTY]: GEOGRAPHIC,
  [OFFICE_ZIP_CODE]: GEOGRAPHIC,
  [OFFICE_STATE]: GEOGRAPHIC,
  [BIRTHDATE]: DATES,
  [JOIN_DATE]: DATES,
  [ADDED_DATE]: DATES,
  [ORIENTATION_DATE]: DATES,
  [LICENSE_EXPIRATION_DATE]: DATES,
  [LAST_PREFERENCE_PAGE_VISIT]: DATES,
  [CREDIT_CARD_EXPIRATION_DATE]: DATES,
  [MEMBERSHIP_STATUS]: MEMBERSHIP_STATUS,
  [MEMBERSHIP_TYPE]: MEMBERSHIP_TYPE,
  [BALANCE_BY_CHARGE_CODE]: BALANCE_BY_CHARGE_CODE,
  [TOTAL_OF_ALL_OUTSTANDING_BALANCES]: TOTAL_OF_ALL_OUTSTANDING_BALANCES,
  [MEMBERSHIP_AND_BILLING_STATUS]: MEMBERSHIP_AND_BILLING_STATUS,
  [MEMBER_DESIGNATIONS]: MEMBER_DESIGNATIONS,
  [COMMITTEES]: COMMITTEES,
  [EVENT_AND_CLASS_REGISTRATION]: EVENT_AND_CLASS_REGISTRATION,
  [COURSE_REGISTRATION]: COURSE_REGISTRATION,
  [EVENT_AND_CLASS_ATTENDANCE]: EVENT_AND_CLASS_ATTENDANCE,
  [COURSE_ATTENDANCE]: COURSE_ATTENDANCE,
  [CODE_OF_ETHICS_COMPLETION]: CODE_OF_ETHICS_COMPLETION,
};

const ContentForFilters = ({ selectedFilters, filterName, memberships }) => {
  const [coeDate, setCoeDate] = useState('');
  const [membershipMap, setMembershipMap] = useState({});

  const getMembershipMap = () => {
    const memMap = generateMembershipMap(memberships);
    setMembershipMap(memMap);
  };

  useEffect(() => {
    const getCodeOfEthicsDate = async () => {
      const date = await getCoeDate();
      setCoeDate(date);
    };

    if (filterName === CODE_OF_ETHICS_COMPLETION) getCodeOfEthicsDate();

    getMembershipMap();
  }, [filterName]);

  const capitalizeFirstLetter = word => {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const getGeoContent = () => {
    return selectedFilters[filterName].map(({ value }, index) => {
      return (
        <ModalRow key={value}>
          {index === 0 ? <Text>Is in</Text> : <Text>or</Text>}
          <Box>{value}</Box>
        </ModalRow>
      );
    });
  };

  const getDateContent = () => {
    return selectedFilters[filterName].map(
      ({ comparisonOperator, date, maxDate, selectedMembership }, index) => {
        let displayDate =
          comparisonOperator === NEVER
            ? ' visited Last Preference Page'
            : moment(date).format('MM/DD/YYYY');
        if (maxDate)
          displayDate += ` and ${moment(maxDate).format('MM/DD/YYYY')}`;
        if (comparisonOperator === RAP_EMPTY_DATE) displayDate = '';
        return (
          <ModalRow key={index}>
            {index !== 0 ? <Text>or</Text> : ''}
            <Box>
              {comparisonOperator} {displayDate}
              {selectedMembership
                ? ` plus Membership ${membershipMap[selectedMembership]}`
                : ''}
            </Box>
          </ModalRow>
        );
      }
    );
  };

  const getMembershipStatusContent = () => {
    return selectedFilters[filterName].map(
      ({ selectedMembership, selectedStatus, status }, index) => {
        return (
          <ModalRow key={`${selectedMembership}-${selectedStatus}`}>
            {index !== 0 ? <Text>or</Text> : ''}
            <Box>
              Membership {membershipMap[selectedMembership]} plus Status{' '}
              {selectedStatus || status}
            </Box>
          </ModalRow>
        );
      }
    );
  };

  const getMembershipTypeContent = () => {
    return selectedFilters[filterName].map(
      ({ selectedMembership, type }, index) => {
        return (
          <ModalRow key={`${selectedMembership}-${type}`}>
            {index !== 0 ? <Text>or</Text> : ''}
            <Box>
              Membership {membershipMap[selectedMembership]} plus Membership
              Type {type}
            </Box>
          </ModalRow>
        );
      }
    );
  };

  const getBalanceByChargeCodeContent = () => {
    return selectedFilters[filterName].map(
      ({ chargeCode, balanceType }, index) => {
        return (
          <ModalRow key={`${chargeCode}-${index}`}>
            {index !== 0 ? <Text>or</Text> : ''}
            <Box>
              Charge code {chargeCode} with{' '}
              {balanceType === 'unpaid' ? 'an' : 'a'} {balanceType} balance
            </Box>
          </ModalRow>
        );
      }
    );
  };

  const getTotalOfAllOutstandingBalancesContent = () => {
    return selectedFilters[filterName].map(
      ({ comparisonOperator, amount, maxAmount }, index) => {
        return (
          <ModalRow key={`${comparisonOperator}=${index}`}>
            {index !== 0 ? <Text>or</Text> : ''}
            <Box>
              A balance {comparisonOperator} {amount}
              {maxAmount ? ` and ${maxAmount}` : ''}
            </Box>
          </ModalRow>
        );
      }
    );
  };

  const getMemberDesignationContent = () => {
    return selectedFilters[filterName].map((designation, index) => (
      <ModalRow key={`${designation}-${index}`}>
        <Box>{designation}</Box>
      </ModalRow>
    ));
  };

  const getCommitteeContent = () => {
    return selectedFilters[filterName].map(
      ({ membership, committee, year }, index) => (
        <ModalRow key={`${committee}-${index}`}>
          <Box>
            Membership {membershipMap[membership]} Committee {committee} in{' '}
            {year}
          </Box>
        </ModalRow>
      )
    );
  };

  const getEventAndClassRegistrationContent = () => {
    return selectedFilters[filterName].map(
      ({ registrationStatus, eventOrClassId }) => (
        <ModalRow key={`${eventOrClassId}`}>
          <Box>
            {capitalizeFirstLetter(registrationStatus)} for Event ID:{' '}
            {eventOrClassId}
          </Box>
        </ModalRow>
      )
    );
  };

  const getCourseRegistrationContent = () => {
    return selectedFilters[filterName].map(
      ({ registrationStatus, courseId, startDate, endDate }) => (
        <ModalRow key={`${courseId}`}>
          <Box>
            {capitalizeFirstLetter(registrationStatus)} for Course ID:{' '}
            {courseId} between {moment(startDate).format('MM/DD/YYYY')} and{' '}
            {moment(endDate).format('MM/DD/YYYY')}
          </Box>
        </ModalRow>
      )
    );
  };

  const getEventAndClassAttendanceContent = () => {
    return selectedFilters[filterName].map(
      ({ attendanceStatus, eventOrClassId }) => (
        <ModalRow key={`${eventOrClassId}`}>
          <Box>
            {capitalizeFirstLetter(attendanceStatus)} Event ID: {eventOrClassId}
          </Box>
        </ModalRow>
      )
    );
  };

  const getCourseAttendanceContent = () => {
    return selectedFilters[filterName].map(
      ({ attendanceStatus, courseId, startDate, endDate }) => (
        <ModalRow key={`${courseId}`}>
          <Box>
            {capitalizeFirstLetter(attendanceStatus)} Course ID: {courseId}{' '}
            between {moment(startDate).format('MM/DD/YYYY')} and{' '}
            {moment(endDate).format('MM/DD/YYYY')}
          </Box>
        </ModalRow>
      )
    );
  };

  const getCodeOfEthicsCompletionContent = () => {
    return (
      <ModalRow>
        <Box>
          Code of Ethics training for the current cycle (ending on {coeDate}) is{' '}
          {selectedFilters[filterName]}
        </Box>
      </ModalRow>
    );
  };

  const renderContent = () => {
    switch (FILTER_MAPPINGS[filterName]) {
      case GEOGRAPHIC:
        return getGeoContent();
      case DATES:
        return getDateContent();
      case MEMBERSHIP_STATUS:
      case MEMBERSHIP_AND_BILLING_STATUS:
        return getMembershipStatusContent();
      case MEMBERSHIP_TYPE:
        return getMembershipTypeContent();
      case BALANCE_BY_CHARGE_CODE:
        return getBalanceByChargeCodeContent();
      case TOTAL_OF_ALL_OUTSTANDING_BALANCES:
        return getTotalOfAllOutstandingBalancesContent();
      case MEMBER_DESIGNATIONS:
      case DESIGNATION_SHOULD_EXIST:
        return getMemberDesignationContent();
      case COMMITTEES:
        return getCommitteeContent();
      case EVENT_AND_CLASS_REGISTRATION:
        return getEventAndClassRegistrationContent();
      case COURSE_REGISTRATION:
        return getCourseRegistrationContent();
      case EVENT_AND_CLASS_ATTENDANCE:
        return getEventAndClassAttendanceContent();
      case COURSE_ATTENDANCE:
        return getCourseAttendanceContent();
      case CODE_OF_ETHICS_COMPLETION:
        return getCodeOfEthicsCompletionContent();
      default:
        return '';
    }
  };

  return <>{renderContent()}</>;
};

const mapStateToProps = state => ({
  selectedFilters: state.audience.selectedFilters,
  memberships: state.organization.selected.memberships || [],
});

export default connect(mapStateToProps)(ContentForFilters);
