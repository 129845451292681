import React from 'react';
import { Card } from '../../../../../../Component/Drafts/Components/_CardUI/Card.styled';
import {
  Container,
  Title,
} from '../../../../../../Component/Drafts/Components/NewCard/NewCard.styled';
import { EditIcon, TemplateName } from './EditActiveCard.styled';

const EditActiveCard = ({ templateName, onClick }) => {
  return (
    <Card onClick={onClick}>
      <Container>
        <EditIcon />
        <Title>Edit Active Template</Title>
        <TemplateName>{templateName}</TemplateName>
      </Container>
    </Card>
  );
};

export default EditActiveCard;
