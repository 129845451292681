export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const escapeRegExp = value => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const modifyWord = word => {
  // turn plural word like topics into topic(s)
  if (word.endsWith('s')) {
    const modifiedWord = word.slice(0, -1) + `(${word.slice(-1)})`;
    return modifiedWord;
  }
  return word;
};

//Creates a simple key/value pair map between membership amsIds and the membership names.
//We do this because the display name should not be used as anything but display, the amsId should be used for all actual logic ideally
export const generateMembershipMap = memberships => {
  if (!memberships) return {};
  const membershipMap = {};
  for (const membership of memberships) {
    membershipMap[membership.amsId] = membership.name;
  }
  return membershipMap;
};
