import React, { useEffect, useState } from 'react';
import { DataGrid } from '../../Users/Users.styled';
import {
  AccountTree,
  Autorenew,
  GetApp,
  DeleteOutline,
  AddOutlined,
} from '@material-ui/icons';
import _union from 'underscore-es/union';
import { CSVLink } from 'react-csv/lib';
import moment from 'moment';
import {
  getOrgGroupMaps,
  fetchOrgTopicsAndGroups,
  refreshAllContactGroups,
  deleteGroupMaps,
} from '../../../../../redux/services/groups';
import { Title } from '../AMSSync.styled';
import { AddButton } from '../../../Tabs/Tabs.styled';
import {
  Container,
  SpaceBetween,
  Button,
  Group,
  DeleteBtn,
} from './GroupMappings.styled';
import GroupMappingDrawer from '../GroupMappingDrawer';
import { useToast, useUpdateUIState } from '../../../../../redux/action';
import { getOrgAPIFields } from '../../../../../redux/services';
import { formCSVData } from './utils';
import DeleteGroupMappingModal from '../DeleteGroupMappingModal';
import { generateMembershipMap } from '../../../../../redux/helpers/helpers';

const columns = [
  {
    headerName: 'Group Name',
    field: 'groupNames',
    minWidth: 100,
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Connected Data',
    field: 'triggers',
    minWidth: 150,
    flex: 2,
    sortable: false,
  },
];

const GroupMapping = ({ organization, userPrivileges }) => {
  const { errorToast, successToast } = useToast();
  const { fullLoader } = useUpdateUIState();
  const [maps, setMaps] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [isEditGroupMappingDrawerOpen, setEditGroupMappingDrawerOpen] =
    useState(false);
  const [isNewGroupMappingDrawerOpen, setNewGroupMappingDrawerOpen] =
    useState(false);
  const [clickedRowData, setClickedRowData] = useState(null);
  const [apiFields, setApiFields] = useState({});
  const [orgMembershipMap, setOrgMembershipMap] = useState({});
  const [mappingCountsData, setMappingCountsData] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedMapIds, setSelectedMapIds] = useState([]);
  const [
    isDeleteGroupMappingModalVisibile,
    setDeleteGroupMappingModalVisibility,
  ] = useState(false);

  const handleRowClick = params => {
    if (!userPrivileges.isSU) return;
    setClickedRowData(params.row);
    setEditGroupMappingDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setEditGroupMappingDrawerOpen(false);
    setClickedRowData(null);
  };

  const getAPIgroups = async () => {
    const orgHeaders = {
      orgId: organization.id,
      orgType: organization.orgType,
    };
    const apiFieldValues = await getOrgAPIFields(orgHeaders);
    if (!apiFieldValues.error) {
      if (Object.keys(apiFieldValues).length > 0) {
        const organizedFields = sortAPIValues(apiFieldValues);
        setApiFields(organizedFields);

        // update the csv data for download
        const formattedMappingCountsData = formCSVData(organizedFields);
        setMappingCountsData(formattedMappingCountsData);
      }
    } else {
      errorToast('Error getting API group information');
    }
  };

  const sortAPIValues = fieldData => {
    Object.keys(fieldData).forEach(field => {
      fieldData[field].sort((a, b) => a.fieldValue.localeCompare(b.fieldValue));
    });

    return fieldData;
  };

  const sortGroups = orgGroups => {
    const organizedGroups = orgGroups.reduce((acc, group) => {
      if (!acc[group.groupTypeName]) {
        acc[group.groupTypeName] = [];
      }
      acc[group.groupTypeName].push(group);
      return acc;
    }, {});

    Object.entries(organizedGroups).forEach(([_, groups]) => {
      groups.sort((a, b) => a.name.localeCompare(b.name));
    });

    return organizedGroups;
  };

  const getOrganizationGroupList = async () => {
    try {
      const groupsInformation = await fetchOrgTopicsAndGroups(organization.id);

      if (!groupsInformation.error) {
        const groupIdToNameMap = {
          ...Object.fromEntries(
            groupsInformation.tags.map(group => [group.id, group.name])
          ),
          ...Object.fromEntries(
            groupsInformation.topics.map(topic => [topic.id, topic.name])
          ),
        };
        const groups = sortGroups(groupsInformation.tags);
        setGroupList(groups);
        setTopics(groupsInformation.topics);
        return groupIdToNameMap;
      } else {
        errorToast('Error retrieving groups.');
      }
    } catch (error) {
      console.error('Error in organizationGroupList:', error);
    }
  };

  const getDisplayValue = (value, memMap) => {
    const membershipId = value.split('_')[0];
    const membershipName = memMap[membershipId];
    if (membershipName) {
      const subValue = value.split('_')[1];
      return `(${membershipName}_${subValue})`;
    } else return `(${value})`;
  };

  const fetchGroupMaps = async () => {
    const memMap = getOrgMembershipMap();
    setOrgMembershipMap(memMap);
    const groupIdToNameMap = await getOrganizationGroupList();
    const maps = await getOrgGroupMaps(organization.id);
    const updatedMaps = [];
    for (const map of maps) {
      const temp = {};
      const result = {
        id: map.id,
        identifiers: map.identifiers,
        emailTags: map.emailTags,
        values: map.values,
      };
      map.values.forEach(val => {
        if (!temp[val.displayName]) {
          temp[val.displayName] = [];
        }
        temp[val.displayName].push(getDisplayValue(val.fieldValue, memMap));
      });

      result.triggers = Object.entries(temp).map(([key, values]) => {
        const joinedValues = values.join(' or ');
        return `(${key}) = ${joinedValues}`;
      });

      result.groupNames = _union(map.identifiers, map.emailTags)
        .map(groupId => {
          return groupIdToNameMap[groupId];
        })
        .sort((groupA, groupB) => {
          return groupA.localeCompare(groupB);
        })
        .map(group => `[${group}]`);
      updatedMaps.push(result);
    }
    setMaps(sortMaps(updatedMaps));
  };

  const sortMaps = maps => {
    return maps.sort((a, b) => {
      const groupNamesLengthDifference =
        b.groupNames.length - a.groupNames.length;
      if (groupNamesLengthDifference !== 0) {
        return groupNamesLengthDifference;
      }

      const triggerA = a.triggers[0] || '';
      const triggerB = b.triggers[0] || '';

      return triggerA.localeCompare(triggerB);
    });
  };

  const getOrgMembershipMap = () => {
    return generateMembershipMap(organization.memberships);
  };

  useEffect(() => {
    fetchGroupMaps();
    getAPIgroups();
  }, [organization]);

  const refreshAllMaps = async () => {
    fullLoader(true, 'Forcing contact group refresh. Please wait.');
    await refreshAllContactGroups({
      orgId: organization.id,
    });
    fullLoader(false);
  };

  const deleteMaps = async () => {
    if (!selectedMapIds.length) return;
    setDeleteGroupMappingModalVisibility(false);

    fullLoader(true, 'Deleting map and auditing contacts');
    const result = await deleteGroupMaps({
      ids: selectedMapIds,
      orgId: organization.id,
    });

    if (result.error) {
      errorToast('Error deleting maps.');
    } else {
      await fetchGroupMaps();
      setSelectedMapIds([]);
      successToast('Deleted map and updated contacts accordingly');
    }
    fullLoader(false);
  };

  return (
    <Container>
      <SpaceBetween>
        <Title>
          <AccountTree size="small" />
          AMS Data Mapping
        </Title>
        <Group>
          {userPrivileges.isSU && (
            <>
              {mappingCountsData.length > 0 && (
                <CSVLink
                  filename={`${
                    organization.organizationName
                  } Membership Count Export ${moment().format(
                    'MM.DD.YYYY'
                  )}.csv`}
                  data={mappingCountsData}
                >
                  <Button>
                    <GetApp size="small" />
                    Export
                  </Button>
                </CSVLink>
              )}
              <Button onClick={refreshAllMaps}>
                <Autorenew size="small" />
                Force Refresh
              </Button>
              <DeleteBtn
                disabled={!selectedMapIds.length}
                onClick={() => setDeleteGroupMappingModalVisibility(true)}
              >
                <DeleteOutline size="small" />
              </DeleteBtn>
              <AddButton
                onClick={() => {
                  setNewGroupMappingDrawerOpen(true);
                }}
              >
                <AddOutlined />
                New
              </AddButton>
            </>
          )}
        </Group>
      </SpaceBetween>
      <DataGrid
        stickyHeader
        rows={maps}
        columns={columns}
        disableColumnSorting={true}
        onRowClick={handleRowClick}
        checkboxSelection
        pageSize={pageSize}
        enabledOnRowClick={userPrivileges.isSU}
        onPageSizeChange={size => setPageSize(size)}
        disableSelectionOnClick
        onSelectionModelChange={newSelectionModel => {
          setSelectedMapIds(newSelectionModel);
        }}
      />
      {isEditGroupMappingDrawerOpen && (
        <GroupMappingDrawer
          data={clickedRowData}
          closeDrawer={handleDrawerClose}
          isGroupMappingDrawerOpen={isEditGroupMappingDrawerOpen}
          setGroupMappingDrawerOpen={setEditGroupMappingDrawerOpen}
          apiFields={apiFields}
          groupList={groupList}
          topics={topics}
          maps={maps}
          orgId={organization.id}
          fetchGroupMaps={fetchGroupMaps}
          orgMembershipMap={orgMembershipMap}
        />
      )}
      {isNewGroupMappingDrawerOpen && (
        <GroupMappingDrawer
          closeDrawer={() => {
            setNewGroupMappingDrawerOpen(false);
          }}
          isGroupMappingDrawerOpen={isNewGroupMappingDrawerOpen}
          setGroupMappingDrawerOpen={setNewGroupMappingDrawerOpen}
          apiFields={apiFields}
          groupList={groupList}
          topics={topics}
          maps={maps}
          orgId={organization.id}
          fetchGroupMaps={fetchGroupMaps}
          orgMembershipMap={orgMembershipMap}
        />
      )}
      {isDeleteGroupMappingModalVisibile && (
        <DeleteGroupMappingModal
          setDeleteGroupMappingModalVisibility={
            setDeleteGroupMappingModalVisibility
          }
          selectedMapIds={selectedMapIds}
          deleteMaps={deleteMaps}
        />
      )}
    </Container>
  );
};

export default GroupMapping;
