import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Select } from 'antd';
import { Container, Strong } from './CopyModal.styled';
import Modal from '../../../Modal';
import {
  selectedOrganizationSelector,
  organizationListSelector,
  userDetailSelector,
} from '../../../../redux/selector';

const { Option } = Select;

const CopyModal = ({
  isCreatingCopy,
  setCopyName,
  copyName,
  createCopy,
  closeCopyModal,
  setNewOrgId,
  newOrgId,
  isDraftCard,
}) => {
  const selectedOrg = useSelector(selectedOrganizationSelector);
  const initialOrgList = useSelector(organizationListSelector);
  const user = useSelector(userDetailSelector);
  const [orgList, setOrgList] = useState(initialOrgList);

  const handleCopyNameChange = event => {
    setCopyName(event.target.value);
  };

  const handleSearch = value => {
    if (value) {
      const filteredOrgs = initialOrgList.filter(field => {
        return field.organizationName
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setOrgList(filteredOrgs);
    } else {
      setOrgList(initialOrgList);
    }
  };

  const mainMessage = isDraftCard ? (
    <span>
      Creates a copy of the <strong>design</strong> of the selected draft.{' '}
      {newOrgId && (
        <>
          <strong>Audience</strong> is not copied over.
        </>
      )}
    </span>
  ) : (
    <span>
      Creates a copy of the <Strong>design</Strong> of the selected template.{' '}
      <Strong>Message info</Strong> is not copied over.
    </span>
  );

  return (
    <Modal
      title="Duplicate Message Design"
      primaryButton={{
        label: 'Save',
        onClick: createCopy,
        isLoading: isCreatingCopy,
      }}
      onClose={closeCopyModal}
    >
      <Container>
        {mainMessage}
        <Input
          value={copyName}
          onInput={handleCopyNameChange}
          placeholder="Name"
        />
        {user.multiOrgUser && (
          <Select
            defaultValue={selectedOrg.id}
            style={{ width: '100%' }}
            onChange={value => {
              setNewOrgId(value);
            }}
            onSearch={handleSearch}
            showSearch
            filterOption={false}
          >
            {orgList.map(org => (
              <Option key={org.id} value={org.id}>
                {org.organizationName}
              </Option>
            ))}
          </Select>
        )}
      </Container>
    </Modal>
  );
};

export default CopyModal;
