import React from 'react';
import {
  TextContainer,
  BoldText,
  ItalicText,
} from './EmptyConversation.styled';

const EmptyConversation = ({ newContact, user, organization }) => {
  return (
    <TextContainer>
      You are starting a new conversation with {newContact.firstName}{' '}
      {newContact.lastName}.
      <BoldText>
        For compliance, the following introductory message will be sent a moment
        before your first text delivers:
      </BoldText>
      <ItalicText>
        {user.firstName} from {organization.acronym} is sending you a text
        message. You can reply STOP anytime to stop messages from this number.
      </ItalicText>
    </TextContainer>
  );
};

export default EmptyConversation;
