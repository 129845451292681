import './ManageAddform.css';
import '../../globalcss.css';
import 'antd/dist/antd.css';

import { LoadingOutlined, WarningFilled } from '@ant-design/icons';
import { Button, TextField } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Col, Row, Switch, Checkbox } from 'antd';
import DatePickerStyled, { TextFieldStyled } from './ManageAddForm.styled';
import { Table, TableBody, TableCell, TableRow } from 'grommet';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import _difference from 'underscore-es/difference';
import _union from 'underscore-es/union';
import { DrawerHeader } from '../DrawerComponents';
import * as actioncreators from '../../redux/action';
import { uuidv4, generateMembershipMap } from '../../redux/helpers/helpers';
import * as services from '../../redux/services';
import {
  addContactToOrg,
  updateContactById,
} from '../../redux/services/contact';
import { updateTopicsSubscriberCounts } from '../../redux/services/subscriberCount';
import ContactCustomFields from '../CustomFields/ContactCustomFields';
import { ChildDrawer } from '../ChildDrawer';
import { ContactNotes } from './ContactNotes';
import CreateGroupModal from '../CreateGroupModal';
import { AmsSyncedItem } from './amsSynced';
import { NonmemberToggle } from './NonmemberToggle';
import {
  fetchSuppressionDetails,
  removeFromSuppressionList,
} from '../../redux/services';
import { toast } from '../../redux/action';
import { capitalizeFirstLetter } from '../Analytics/utils';

const ManageAddform = props => {
  moment.locale('en');
  const [activeEmailTopics, setActiveEmailTopics] = useState([]);
  const [textTags, setTextTags] = useState([]);
  const [tempTags, setTempTags] = useState([]);
  const [activeIdentifiers, setActiveIdentifiers] = useState([]);
  const [ContactFirstName, setContactFirstName] = useState('');
  const [ContactLastName, setContactLastName] = useState('');
  const [ContactNickname, setContactNickname] = useState('');
  const [ContactEmail, setContactEmail] = useState('');
  const [ContactPhoneNumber, setContactPhoneNumber] = useState('');
  const [ContactBirthdate, setContactBirthdate] = useState('');
  const [AMSContact, setAMSContact] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [CustomFieldsVisible, setCustomFieldsVisible] = useState(false);
  const [isNewGroupModalVisible, setIsNewGroupModalVisible] = useState(false);
  const [contactMemberId, setContactMemberId] = useState('');
  const [suppressionDetails, setSuppressionDetails] = useState(false);
  const [invalidEmailTextVisible, setInvalidEmailTextVisible] = useState(false);
  const [invalidEmailEntered, setInvalidEmailEntered] = useState(false);
  const [invalidPhoneNumberEntered, setInvalidPhoneNumberEntered] =
    useState(false);
  const [orgMembershipMap, setOrgMembershipMap] = useState({});
  const dispatch = useDispatch();

  const openNewTagModal = useCallback(() => {
    setIsNewGroupModalVisible(true);
  }, []);

  const closeNewTagModal = useCallback(() => {
    setIsNewGroupModalVisible(false);
  }, []);

  const {
    update,
    organization: {
      selected: { id: selectedOrganizationId },
    },
  } = props;
  const handleNewTagCreation = useCallback(async () => {
    closeNewTagModal();
    await update(selectedOrganizationId);
  }, [closeNewTagModal, selectedOrganizationId, update]);

  // useEffect(() => {
  //   //If the member/contact has 1 or more email tags or text tags, they must have a provided email or phone number respectively.
  //   formRef.current.validateFields(['email', 'phone'], { force: true });
  // }, [activeEmailTopics, activeTextTopics]);

  const populateForm = useCallback(() => {
    if (props?.data?.organization?.id) {
      // Check for email tags in the selected member
      if (props.data.emailTags) {
        setActiveEmailTopics(props.data.emailTags);
      }
      if (props.data.identifiers) {
        setActiveIdentifiers(props.data.identifiers);
      }

      if (props.data.source) {
        //Disable certain input fields if the member/contact is imported from an AMS.
        const platform = props.data?.source?.name?.toLowerCase() || 'nr';
        if (!['nr', 'csv'].includes(platform)) setAMSContact(true);
      }

      if (props.data.spamAlert || props.data.bounceAlert) {
        fetchSuppressionDetails(
          props?.data?.organization?.id,
          props.data.noteRouterId
        ).then(data => {
          const { errorMessage, eventName, time, _id, bounceType } = data;
          setInvalidEmailTextVisible(data?.invalidEmail);
          setSuppressionDetails({
            errorMessage: errorMessage || 'N/A',
            eventName: eventName || 'N/A',
            time,
            eventId: _id,
            bounceType,
          });
        });
      }

      if (props.data?.isMember) setIsMember(true);
      if (props.data?.source?.memberId)
        setContactMemberId(props.data.source.memberId);

      setTextTags(props?.data?.textTags || []);
      setTempTags(props?.data?.tempTags || []);
      setContactBirthdate(props.data.birthdate);
      setContactFirstName(props?.data?.firstName);
      setContactLastName(props?.data?.lastName);
      setContactNickname(props?.data?.nickname);
      setContactEmail(props?.data?.email);
      setContactPhoneNumber(props?.data?.phoneNumber);
    }
  }, [props]);

  const filterTopicsForContactType = topicsToFilter => {
    // only allow members to be subscribed to member topics and only nonmembers to be subscribed to nonmember topics
    return topicsToFilter.filter(topicID =>
      isMember
        ? props.topics?.find(
            topic => topic.id === topicID && topic.availableTo.member
          )
        : props.topics?.find(
            topic => topic.id === topicID && topic.availableTo.nonMember
          )
    );
  };

  // Adding user by the giving data
  const handleSubmit = async () => {
    // filter topics for the appropriate contact type so that we don't have non-members subscribed to member topics and vice-versa
    const filteredEmailTopics = filterTopicsForContactType(activeEmailTopics);

    // when contact is toggled from non-member to member
    const newlyMadeMember = !props.data?.isMember && isMember;
    const mandatoryTopics = props.topics
      .filter(topic => topic.mandatory)
      .map(_ => _.id);

    const contactData = {
      firstName: ContactFirstName || props.data?.firstName || '',
      lastName: ContactLastName || props.data?.lastName || '',
      nickname: ContactNickname || props.data?.nickname || '',
      emailTags: newlyMadeMember
        ? _union(filteredEmailTopics, mandatoryTopics) // automatically turn on any Mandatory Topic for Contacts when a non-member is made a member
        : filteredEmailTopics,
      textTags: textTags,
      identifiers: activeIdentifiers,
      updatedDate: moment().unix(),
      organizationId: props.organization.selected.id,
      'organization.id': props.organization.selected.id,
      tempTags,
    };

    if (moment(ContactBirthdate, 'YYYY-MM-DD', true).isValid()) {
      contactData.birthdate = ContactBirthdate;
    }

    const cleanEmail = ContactEmail.toLowerCase().trim();

    if (cleanEmail) contactData.email = cleanEmail;
    else if (!props.data) contactData.email = '';
    if (props.data && cleanEmail !== props.data.email) {
      //If there is an updated email, remove spam/bounce flag
      contactData.spamAlert = false;
      contactData.bounceAlert = false;
    }

    if (ContactPhoneNumber) {
      // since the text input field for phone number can allow dashes, remove them before saving
      // e.g. 661-123-5467 should be saved as 6611234567
      contactData.phoneNumber = ContactPhoneNumber.replace(/-/g, '');
    } else if (!props.data) contactData.phoneNumber = '';
    if (props.data && ContactPhoneNumber !== props.data.phoneNumber) {
      //if updated phone number, remove smsAlert flag
      contactData.smsAlert = false;
    }

    // If editing existing member/contact
    if (props?.data?.organization?.id) {
      //These fields can only be updated for non AMS contacts
      if (!AMSContact) {
        contactData.isMember = isMember;
        contactData.source = {
          memberId: contactMemberId,
          name: props?.data?.source?.name,
        };
      }
      contactData.noteRouterId = props.data.noteRouterId;
      props.actions.fullloader(true, 'Updating Contact.');
      const updateMem = await updateContactById({
        ...contactData,
        updatedBy: props.user.detail.id,
      });
      if (!updateMem.error) {
        props.actions.toast(
          'success',
          'Contact record successfully updated.',
          2
        );
      } else {
        props.actions.toast('error', 'Error updating contact');
      }

      // IF DONT HAVE DATA PROP
      // aka, creating a new contact
    } else {
      const newContactData = {
        ...contactData,
        delete: false,
        timezone: props.organization.selected.timezone,
        emailTags: isMember
          ? _union(filteredEmailTopics, mandatoryTopics)
          : filteredEmailTopics, // make sure only topics that apply to contact type (member or nonmember) get saved
        identifiers: activeIdentifiers,
        createdDate: moment().unix(),
        noteRouterId: uuidv4(),
        organization: {
          id: props.organization.selected.id,
          memberships: [],
          purchases: [],
          attributes: [],
        },
        source: {
          name: 'nr',
          memberId: isMember ? contactMemberId : '',
        },
        isMember,
      };
      newContactData.hashId = newContactData.noteRouterId.substring(0, 5);
      const newMem = await addContactToOrg(newContactData);
      if (!newMem.error) {
        const { topicSubscriberUpdateCounts } =
          getTagAndTopicUpdateCounts(filteredEmailTopics);
        await updateTopicsSubscriberCounts(topicSubscriberUpdateCounts);
        props.actions.toast('success', 'Contact added successfully.', 2);
      } else {
        props.actions.toast('error', 'Error adding contact');
      }
      setContactBirthdate('');
      setContactFirstName('');
      setContactLastName('');
      setContactNickname('');
      setContactEmail('');
      setContactPhoneNumber('');
      setActiveEmailTopics([]);
      setTempTags([]);
      setTextTags([]);
      setActiveIdentifiers([]);
    }
    props.closeDrawer();
    props.update(props.organization.selected.id);
    props.actions.fullloader(false);
  };

  const getTagAndTopicUpdateCounts = newEmailTopics => {
    let existingEmailTopics = props.data ? props.data.emailTags : [];
    let existingIdentifiers = props.data ? props.data.identifiers : [];

    let emailTopicsToIncrement = _difference(
      newEmailTopics,
      existingEmailTopics
    );

    let emailTopicsToDecrement = _difference(
      existingEmailTopics,
      newEmailTopics
    );

    let tagsToIncrement = _difference(activeIdentifiers, existingIdentifiers);
    let tagsToDecrement = _difference(existingIdentifiers, activeIdentifiers);

    let tagCounts = {};
    let topicCounts = {};
    let emailTopicCounts = {};
    let textTopicCounts = {};

    for (let tag of tagsToIncrement) {
      if (!tagCounts[tag]) tagCounts[tag] = 1;
    }
    for (let tag of tagsToDecrement) {
      if (!tagCounts[tag]) tagCounts[tag] = -1;
    }

    for (let topic of emailTopicsToIncrement) {
      if (!emailTopicCounts[topic]) emailTopicCounts[topic] = 1;
    }
    for (let topic of emailTopicsToDecrement) {
      if (!emailTopicCounts[topic]) emailTopicCounts[topic] = -1;
    }

    return {
      topicSubscriberUpdateCounts: {
        totalTopicCounts: topicCounts,
        emailTopicCounts,
        textTopicCounts,
      },
    };
  };

  const handleEmail = (e, id) => {
    if (e === true) {
      setActiveEmailTopics([...activeEmailTopics, id]);
    } else {
      const array = [...activeEmailTopics];
      const index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
        setActiveEmailTopics(array);
      }
    }
  };

  const handleText = (e, id) => {
    // Turn the text switch 'On'
    if (e === true) {
      if (props?.data?.textTags.length && !textTags.includes(id)) {
        //If the topic already has ANY text topic, we don't need to set them as temporary and get confirmation.
        //Sets the switch to be blue
        setTextTags([...textTags, id]);
      } else {
        //Sets the switch to be orange
        setTempTags([...tempTags, id]);
      }
    } else {
      // Turn the text switch 'Off'
      if (tempTags.includes(id)) {
        const array = [...tempTags];
        const index = array.indexOf(id);
        array.splice(index, 1);
        setTempTags(array);
      }
      if (textTags.includes(id)) {
        const array = [...textTags];
        const index = array.indexOf(id);
        array.splice(index, 1);
        setTextTags(array);
      }
    }
  };

  const tagcheckbox = (e, id) => {
    if (e.target.checked === true) {
      setActiveIdentifiers([...activeIdentifiers, id]);
    } else {
      const array = [...activeIdentifiers];
      const index = array.indexOf(id);
      if (index > -1) {
        array.splice(index, 1);
        setActiveIdentifiers(array);
      }
    }
  };

  const generateEmail = (contact, org, sender) => {
    return {
      to: contact.email,
      from: `${org.fromEmail}@${org.domains[0]}`,
      subject: `Hi ${contact.firstName}, here's your ${org.acronym} Preference Form`,
      html: `<html lang="en">
        <p>
        Hi ${contact.firstName}, here's your ${org.acronym} Preference Form link (courtesy of ${sender.firstName} ${sender.lastName}!) Click the link below to customize your communication preferences so that we know what you need (and don't need) for your business.
        </p>
        <a href="${process.env.REACT_APP_webUrl}/optinmsg?id=${contact.noteRouterId}" target="_blank">
          ${process.env.REACT_APP_webUrl}/optinmsg?id=${contact.noteRouterId}
        </a>
        <p>If you have any questions, let us know at ${org.email} or ${org.phoneNumber}.</p>
        <p>- ${org.organizationName}</p>
      </html>`,
    };
  };

  const sendPreferenceForm = async () => {
    const email = generateEmail(
      props.data,
      props.organization.selected,
      props.user.detail
    );
    const result = await services.sendMail(email);

    !result.error && result.message !== 'Unsent'
      ? props.actions.toast('success', 'Preference Form sent via email.')
      : props.actions.toast('error', 'Failed to send email');
  };

  const getRelevantTopics = () => {
    const topics = props.topics || [];

    const filteredTopics = topics.filter(topic => {
      const { member, nonMember } = topic.availableTo || {
        member: true,
        nonMember: false,
      };
      // contact is a member and topic is availabe to members
      if (isMember && member) return true;
      // contact is not a member and topic is availabe to nonmembers
      if (!isMember && nonMember) return true;
      // in all other cases return false
      return false;
    });

    return filteredTopics;
  };

  const reenableDeliveries = () => {
    removeFromSuppressionList(
      props?.data?.organization?.id,
      props.data.noteRouterId,
      suppressionDetails.eventId
    ).then(response => {
      if (response?.status === 200) {
        setSuppressionDetails(false);
        if (suppressionDetails.eventName === 'spamreport') {
          props.data.spamAlert = false;
        }
        if (
          suppressionDetails.eventName === 'bounce' ||
          suppressionDetails.eventName === 'dropped'
        ) {
          props.data.bounceAlert = false;
        }
        mapStateToProps(props);
        dispatch(
          toast(
            'success',
            'Contact’s email address successfully removed from suppression list.'
          )
        );
      } else {
        dispatch(
          toast(
            'error',
            'Error - there was a problem removing contact’s email address from suppression list. Please contact support using the “Help” button on the left.',
            10
          )
        );
      }
    });
  };

  const showOfficeDataTitle = useMemo(() => {
    const attributes = props?.data;
    if (!props.data) return false;

    const officeAttributes = [
      'officeCity',
      'officeCounty',
      'officeState',
      'officeZipCode',
    ];
    return Object.keys(attributes)?.some(attr =>
      officeAttributes.includes(attr)
    );
  }, [props]);

  const showMembershipDataTitle = useMemo(() => {
    const memberships = props?.data?.organization?.memberships;
    const hasJoinDateInfo = memberships?.some(_ => _.startDate || _.name);
    const hasOrientationDateInfo = memberships?.attributes?.find(
      attr => attr.name === 'memberAssociationOrientationDate'
    )?.value;
    return hasJoinDateInfo || !!hasOrientationDateInfo;
  }, [props]);

  const displayBounceType = bounceEvent => {
    const { bounceType } = bounceEvent;
    if (!bounceType) return capitalizeFirstLetter(bounceEvent.eventName);
    if (bounceType === 'bounce') return 'Permanent Bounce';
    return 'Temporary Bounce';
  };

  const tempBounceCopy =
    "A Temporary Bounce means that the recipient's email server rejected the message, but will still try to receive future messages. The recipient's email address may be experiencing issues, or may not trust your message. Repeated Temporary Bounces are likely to become Permanent Bounces, so it can be a good idea to ask the recipient or their IT admin to resolve the error being reported by their inbox shown above.";
  const genericBounceCopy =
    'To protect your sender reputation, future messages\n' +
    '                          will not attempt to deliver to this email address. If\n' +
    '                          you believe the cause of this error has been resolved,\n' +
    '                          you can reenable deliveries to this email address\n' +
    '                          below.';

  useEffect(() => {
    const mappedMemberships = generateMembershipMap(
      props.organization.selected.memberships
    );
    setOrgMembershipMap(mappedMemberships);
    populateForm();
    window.scrollTo(0, 0);
  }, [props, populateForm]);

  const displayLicenseExpiration = () => {
    const stateLicenseExpiration = props?.data?.organization?.attributes?.find(
      _ => _.name === 'memberStateLicenseExpirationDate'
    )?.value;

    //If no value, do not show anything
    if (!stateLicenseExpiration) return <Row gutter={16} />;

    return (
      <Row gutter={16}>
        <Col span={8} style={{ marginBottom: '12px' }}>
          <TextField
            label="License Expiration Date:"
            InputProps={{ disableUnderline: true }}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            value={stateLicenseExpiration}
            disabled={true}
            style={{
              width: '100%',
            }}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <DrawerHeader
        onClose={props.closeDrawer}
        onSave={handleSubmit}
        title={props.drawerTitle}
      />
      <div className="ContactDetailDrawerContainer">
        <Row gutter={16}>
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              paddingLeft: '20px',
            }}
          >
            {!props.isLoading && (
              <NonmemberToggle
                onToggle={val => {
                  setIsMember(val);
                }}
                updateMemberId={val => {
                  setContactMemberId(val);
                }}
                memberId={props?.data?.source?.memberId}
                isMember={props?.data?.isMember}
                amsContact={AMSContact}
              />
            )}
            {AMSContact && (
              <span style={{ paddingLeft: '50px' }}>
                <AmsSyncedItem text={'AMS-synced'} color={'#007ade'} />
              </span>
            )}
          </div>
        </Row>
        <div style={{ height: '100%', padding: '10px' }}>
          <p className="groupsTitle spacehead">Contact Info</p>
          <Row gutter={16}>
            <Col span={8} style={{ marginBottom: '12px' }}>
              <TextField
                label="First Name"
                name="firstname"
                InputProps={{ disableUnderline: true }}
                size="small"
                onChange={event => setContactFirstName(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                value={ContactFirstName}
                disabled={AMSContact}
                style={{
                  width: '100%',
                }}
              />
            </Col>
            <Col span={8} style={{ marginBottom: '12px' }}>
              <TextField
                label="Last Name"
                InputProps={{ disableUnderline: true }}
                size="small"
                onChange={event => setContactLastName(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                value={ContactLastName}
                disabled={AMSContact}
                style={{
                  width: '100%',
                }}
              />
            </Col>
            <Col span={8} style={{ marginBottom: '12px' }}>
              <TextField
                label="Alias/Nickname"
                InputProps={{ disableUnderline: true }}
                size="small"
                onChange={event => setContactNickname(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                value={ContactNickname}
                disabled={AMSContact}
                style={{
                  width: '100%',
                }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={14} style={{ marginBottom: '12px' }}>
              <TextFieldStyled
                label="Email"
                InputProps={{ disableUnderline: true }}
                size="small"
                onChange={event => {
                  setContactEmail(event.target.value);
                  setInvalidEmailEntered(
                    !/^$|^.*@.*\..*$/.test(event.target.value)
                  );
                }}
                value={ContactEmail}
                error={
                  props?.data?.spamAlert ||
                  props?.data?.bounceAlert ||
                  invalidEmailEntered
                }
                helperText={
                  invalidEmailEntered ? 'The input is not valid email' : ''
                }
                disabled={AMSContact}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: '100%',
                }}
              />
            </Col>
            <Col span={10} style={{ marginBottom: '12px' }}>
              <TextFieldStyled
                label="Phone"
                InputProps={{ disableUnderline: true }}
                size="small"
                onChange={event => {
                  const value = event.target.value;
                  const phoneNumberWithOnlyAllowedChars = value.replace(
                    /[^0-9-]/g,
                    ''
                  );
                  setInvalidPhoneNumberEntered(
                    !/^(?:\d{3}-)?\d{3}-\d{4}$|^\d{10}$/.test(
                      phoneNumberWithOnlyAllowedChars
                    )
                  );
                  setContactPhoneNumber(phoneNumberWithOnlyAllowedChars);
                }}
                value={ContactPhoneNumber}
                error={props?.data?.smsAlert || invalidPhoneNumberEntered}
                helperText={
                  props?.data?.smsAlert
                    ? 'This phone number is experiencing delivery issues. Check Analytics for more details.'
                    : invalidPhoneNumberEntered
                    ? 'The input is not valid phone number'
                    : ''
                }
                disabled={AMSContact}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  width: '100%',
                }}
              />
            </Col>
            <Row gutter={16}>
              <Col span={24} style={{ marginBottom: '12px' }}>
                {suppressionDetails &&
                Object.keys(suppressionDetails).length ? (
                  <div
                    style={{
                      fontSize: '12px',
                      color: 'black',
                      marginTop: '10px',
                      backgroundColor: '#FFEFEE',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                  >
                    <p style={{ marginBottom: '0px' }}>
                      Delivery error reported:{' '}
                      <b>
                        {suppressionDetails.time
                          ? moment(suppressionDetails.time * 1000).format(
                              'MMMM DD, YYYY [at] h:mm A'
                            )
                          : 'N/A'}
                      </b>
                    </p>
                    <p style={{ marginBottom: '10px ' }}>
                      Error:{' '}
                      <b>
                        <span style={{ textTransform: 'capitalize' }}>
                          {displayBounceType(suppressionDetails)}
                        </span>{' '}
                        - {suppressionDetails.errorMessage}
                      </b>
                    </p>
                    {invalidEmailTextVisible ? (
                      <p>
                        To protect your sender reputation, future messages will
                        not attempt to deliver to this email address. This email
                        address has a typo or similar error and should be
                        replaced with a working email address.
                      </p>
                    ) : (
                      <div>
                        <p style={{ marginBottom: '10px ' }}>
                          {suppressionDetails.bounceType === 'blocked'
                            ? tempBounceCopy
                            : genericBounceCopy}
                        </p>
                        {suppressionDetails?.bounceType !== 'blocked' && (
                          <>
                            <p
                              style={{ marginBottom: '5px', fontWeight: '600' }}
                            >
                              <WarningFilled style={{ color: 'orange' }} />
                              &nbsp; Caution: Reenabling deliveries for a
                              contact multiple times can hurt your sender
                              reputation and cause more of your emails to
                              deliver as spam.
                            </p>
                            <a onClick={reenableDeliveries}>
                              <u>Reenable Deliveries</u>
                            </a>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ) : null}
              </Col>
            </Row>
          </Row>
          <Row gutter={16}>
            <Col span={12} style={{ marginBottom: '12px' }}>
              <div
                style={{ fontSize: '12px', color: 'gray', marginTop: '-5px' }}
              >
                Birth Date
              </div>
              <DatePickerStyled
                format="YYYY-MM-DD"
                size="small"
                value={ContactBirthdate ? moment(ContactBirthdate) : null}
                onChange={(date, dateString) => {
                  setContactBirthdate(dateString);
                }}
                disabled={AMSContact}
              />
            </Col>
          </Row>
          {showOfficeDataTitle && (
            <p className="groupsTitle spacehead">Office Info</p>
          )}
          {showOfficeDataTitle && (
            <Row gutter={16}>
              <Col span={16} style={{ marginBottom: '12px' }}>
                <TextField
                  label="Office ID"
                  name="officeid"
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  style={{
                    width: '100%',
                  }}
                  value={props.data.officeId}
                />
              </Col>
              {props?.data?.source?.name !== 'tangilla' && (
                <Col span={16} style={{ marginBottom: '12px' }}>
                  <TextField
                    label="Office County"
                    name="Officecounty"
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={true}
                    style={{
                      width: '100%',
                    }}
                    value={props.data.officeCounty}
                  />
                </Col>
              )}
              <Col span={8} style={{ marginBottom: '12px' }}>
                <TextField
                  label="Office State"
                  name="Officestate"
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  style={{
                    width: '100%',
                  }}
                  value={props.data.officeState}
                />
              </Col>
              <Col span={16} style={{ marginBottom: '12px' }}>
                <TextField
                  label="Office City"
                  name="Officecity"
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  style={{
                    width: '100%',
                  }}
                  value={props.data.officeCity}
                />
              </Col>
              <Col span={8} style={{ marginBottom: '12px' }}>
                <TextField
                  label="Office Zip Code"
                  name="officeZipCode"
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={true}
                  style={{
                    width: '100%',
                  }}
                  value={props.data.officeZipCode}
                />
              </Col>
            </Row>
          )}
          {showMembershipDataTitle && (
            <p className="groupsTitle spacehead">Membership Info</p>
          )}
          <Row gutter={16}>
            {props?.data?.organization?.memberships?.map((mship, i) => {
              const displayName = orgMembershipMap[mship.name] || mship.name;
              return (
                <Col span={8} key={i} style={{ marginBottom: '12px' }}>
                  <TextField
                    label={`Status (${displayName})`}
                    name={`memberAssociationStatus${i}`}
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={mship.status}
                    disabled={true}
                    style={{
                      width: '100%',
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          <Row gutter={16}>
            {props?.data?.organization?.memberships?.map((mship, i) => {
              const displayName = orgMembershipMap[mship.name] || mship.name;
              const joinDate = mship.startDate;
              if (!joinDate) return;
              return (
                <Col span={8} key={i} style={{ marginBottom: '12px' }}>
                  <TextField
                    label={`Join Date (${displayName})`}
                    name={`memberAssociationJoinDate${i}`}
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={joinDate}
                    disabled={true}
                    style={{
                      width: '100%',
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          <Row gutter={16}>
            {props?.data?.organization?.memberships?.map((mship, i) => {
              const displayName = orgMembershipMap[mship.name] || mship.name;
              const orientation = mship.attributes?.find(
                attr => attr.name === 'memberAssociationOrientationDate'
              )?.value;
              if (!orientation) return;

              return (
                <Col span={8} key={i} style={{ marginBottom: '12px' }}>
                  <TextField
                    label={`Orientation Date (${displayName})`}
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={orientation}
                    disabled={true}
                    style={{
                      width: '100%',
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          <Row gutter={16}>
            {props?.data?.organization?.memberships?.map((mship, i) => {
              const displayName = orgMembershipMap[mship.name] || mship.name;
              const billType = mship.attributes?.find(
                attr => attr.name === 'memberAssociationBillType'
              )?.value;
              if (!billType) return;

              return (
                <Col span={8} key={i} style={{ marginBottom: '12px' }}>
                  <TextField
                    label={`Member Subtype (${displayName})`}
                    size="small"
                    InputProps={{ disableUnderline: true }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={billType}
                    disabled={true}
                    style={{
                      width: '100%',
                    }}
                  />
                </Col>
              );
            })}
          </Row>
          {showMembershipDataTitle && displayLicenseExpiration()}
          {props.data && (
            <Col span={24} style={{ marginBottom: '12px' }}>
              <Button
                InputProps={{ disableUnderline: true }}
                onClick={() => {
                  setCustomFieldsVisible(true);
                }}
                style={{
                  background: 'rgba(0, 80, 146, 0.05)',
                  borderColor: '#005092',
                  color: '#005092',
                  width: '100%',
                  marginTop: '31px',
                  marginBottom: '25px',
                }}
              >
                View All Data
              </Button>
            </Col>
          )}
          {!!props.data?.noteRouterId && (
            <ContactNotes
              parentId={props.parentId}
              username={`${props.data.firstName} ${props.data.lastName}`}
              noteRouterId={props.data?.noteRouterId}
            />
          )}
          <Row gutter={16} />
          <Row>
            <Col span={24}>
              {/* Table start */}
              <Table className="taggedtable" style={{ marginLeft: '0px' }}>
                <TableRow className="">
                  <TableCell className="taggedwidth">
                    <p className="taggedpara">
                      <b>Topics</b>
                    </p>
                  </TableCell>
                  <TableCell>
                    <p className="taggedpara">
                      <b>How They Receive It</b>
                    </p>
                  </TableCell>
                </TableRow>

                <TableBody>
                  <TableRow className="taggedHead">
                    <TableCell className="taggedwidth">
                      <p className="taggedheader">Name</p>
                    </TableCell>
                    <TableCell>
                      <p className="taggedheader">Email</p>
                    </TableCell>
                    <TableCell>
                      <p className="taggedheader">Text</p>
                    </TableCell>
                  </TableRow>
                  {getRelevantTopics().map((item, index) => {
                    if (
                      (item.editEmailPreference ||
                        item.editEmailPreference === undefined) &&
                      (item.editTextPreference ||
                        item.editTextPreference === undefined)
                    ) {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <p className="taggedbody">{item.name}</p>
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={activeEmailTopics.indexOf(item.id) > -1}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              onChange={e => {
                                handleEmail(e, item.id);
                              }}
                              className="Emailcheck"
                            />
                          </TableCell>
                          <TableCell>
                            <Switch
                              style={{
                                backgroundColor: `${
                                  tempTags.includes(item.id) ? 'orange' : ''
                                }`,
                              }}
                              checked={
                                textTags.includes(item.id) ||
                                tempTags.includes(item.id)
                              }
                              disabled={
                                !(
                                  props.organization.selected
                                    .editTextPreferences ||
                                  props.user.userPrivileges.isSU ||
                                  textTags.includes(item.id) ||
                                  tempTags.includes(item.id)
                                )
                              }
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              onChange={e => {
                                handleText(e, item.id);
                              }}
                              className="Textcheck Emailcheck"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (
                      item.editEmailPreference ||
                      item.editEmailPreference === undefined
                    ) {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <p className="taggedbody">{item.name}</p>
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={activeEmailTopics.indexOf(item.id) > -1}
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              onChange={e => {
                                handleEmail(e, item.id);
                              }}
                              className="Emailcheck"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (
                      item.editTextPreference ||
                      item.editTextPreference === undefined
                    ) {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <p className="taggedbody">{item.name}</p>
                          </TableCell>
                          <TableCell />
                          <TableCell>
                            <Switch
                              checked={
                                textTags.includes(item.id) ||
                                tempTags.includes(item.id)
                              }
                              disabled={
                                !(
                                  props.organization.selected
                                    .editTextPreferences ||
                                  props.user.userPrivileges.isSU ||
                                  textTags.includes(item.id) ||
                                  tempTags.includes(item.id)
                                )
                              }
                              checkedChildren="ON"
                              unCheckedChildren="OFF"
                              onChange={e => {
                                handleText(e, item.id);
                              }}
                              className="Textcheck Emailcheck"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
              {tempTags.length > 0 && (
                <span>
                  *An orange switch means that a staff user turned this on, but
                  the member has not yet confirmed the change.
                </span>
              )}
              {/* Table end */}
              {props.data && (
                <Button
                  size={'small'}
                  InputProps={{ disableUnderline: true }}
                  disabled={!props.data.noteRouterId}
                  onClick={
                    props.data.noteRouterId ? sendPreferenceForm : () => {}
                  }
                  style={{
                    background: 'rgba(231, 151, 45, 0.05)',
                    borderColor: '#e7972d',
                    color: '#e7972d',
                    float: 'right',
                  }}
                >
                  Send Preference Form
                </Button>
              )}
            </Col>
            <Col span={1} />
          </Row>
          <div className="groupsHeader">
            <p className="groupsTitle spacehead">Groups</p>
            <Button
              className="newGroupButton"
              startIcon={<AddCircleIcon />}
              size="small"
              onClick={openNewTagModal}
            >
              New Group
            </Button>
            {isNewGroupModalVisible && (
              <CreateGroupModal
                onSuccess={handleNewTagCreation}
                onClose={closeNewTagModal}
              />
            )}
          </div>
          <Row>
            <Col span={24} className="checkidenti">
              {props.groups && props.groups.length > 0
                ? props.groups.map((item, index) => {
                    return (
                      <p className="identifont" key={index}>
                        <Checkbox
                          checked={activeIdentifiers.indexOf(item.id) > -1}
                          onChange={e => tagcheckbox(e, item.id)}
                          className="checkidenti1"
                        />
                        {item.name}
                      </p>
                    );
                  })
                : ''}
            </Col>
          </Row>
        </div>
        {props.data && (
          <ChildDrawer
            parentId={props.parentId}
            anchor="right"
            open={CustomFieldsVisible}
            onClose={() => setCustomFieldsVisible(false)}
            Component={
              <ContactCustomFields
                contactDetails={props.data}
                drawerTitle={`Custom Fields:  ${props.data?.firstName} ${props.data?.lastName}`}
                closeDrawer={() => {
                  setCustomFieldsVisible(false);
                }}
                updatedBasicFields={() =>
                  props.updateDrawer({
                    noteRouterId: props.data.noteRouterId,
                  })
                }
              />
            }
          />
        )}
        <div
          className="ContactDrawerLoading"
          style={{ display: props.isLoading ? 'flex' : 'none' }}
        >
          <div className="contentDiv">
            <LoadingOutlined />
            <p>Loading contact details</p>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return state;
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actioncreators, dispatch),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageAddform)
);
