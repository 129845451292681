import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const MembershipAndBillingStatus = ({
  filterName,
  memberships,
  checked,
  selectedFilters,
  orgTypeName,
}) => {
  const dispatch = useDispatch();

  const [selectedMembership, setMembership] = useState({});
  const [status, setStatus] = useState('');
  const [membershipAndBillingStatus, setMembershipAndStatus] = useState(
    selectedFilters || []
  );

  useEffect(() => {
    if (membershipAndBillingStatus.length) {
      dispatch(addFilter({ [filterName]: membershipAndBillingStatus }));
    } else if (
      membershipAndBillingStatus.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [membershipAndBillingStatus]);

  useEffect(() => {
    if (!checked && membershipAndBillingStatus.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleMembershipChange = ({ target }) => {
    const membershipName = target.value;
    const matchingMembership = memberships.find(
      mem => mem.name.trim() === membershipName.trim()
    );
    if (!matchingMembership) {
      dispatch(toast('error', 'Error selecting Membership.'));
      return;
    }
    setMembership(matchingMembership);
  };

  const handleStatusChange = ({ target }) => {
    setStatus(target.value);
  };

  const handleAddMembershipAndStatus = () => {
    const errorMessage = [];
    if (!Object.keys(selectedMembership).length) {
      errorMessage.push('Please select Membership.');
    }
    if (!status) {
      errorMessage.push('Please add Status.');
    }
    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setMembershipAndStatus([
        ...membershipAndBillingStatus,
        { selectedMembership: selectedMembership.amsId, status },
      ]);
      setMembership({});
      setStatus('');
    }
  };

  const handleRemoveMembershipAndStatus = index => {
    setMembershipAndStatus([
      ...membershipAndBillingStatus.slice(0, index),
      ...membershipAndBillingStatus.slice(index + 1),
    ]);
  };

  const getAssociationOrMembership = () => {
    return orgTypeName === 'magic' ? 'Association' : 'Membership';
  };

  const getMembershipName = membershipId => {
    const matchingMembership = memberships.find(
      mem => mem.amsId === membershipId
    );
    return matchingMembership.name;
  };

  const getBillingStatusName = () => {
    return orgTypeName === 'magic' ? 'plus Billing status' : 'plus Status';
  };

  return (
    <Container>
      {(selectedFilters || []).map(({ selectedMembership, status }, index) => (
        <Wrap key={index}>
          <FitContent>
            {index > 0 ? <Space>or</Space> : <Space>-</Space>}{' '}
            {getAssociationOrMembership()}
          </FitContent>{' '}
          <Bold>{getMembershipName(selectedMembership)}</Bold>
          <FitContent>{getBillingStatusName()}</FitContent>
          <Bold>{status}</Bold>
          <CancelOutlined
            fontSize="small"
            style={{ fill: '#E63946' }}
            onClick={() => handleRemoveMembershipAndStatus(index)}
          />
        </Wrap>
      ))}
      <Content>
        <Text>
          {membershipAndBillingStatus.length ? 'or' : ''}
          {getAssociationOrMembership()}
        </Text>
        <SelectDropDown
          value={selectedMembership.name || ''}
          menuItemNames={memberships.map(mem => mem.name)}
          onChange={handleMembershipChange}
        />
      </Content>
      <Content>
        <Text>{getBillingStatusName()}</Text>
        <TextField
          value={status}
          onChange={handleStatusChange}
          style={{ width: 60 }}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddMembershipAndStatus}
      >
        Add
      </Button>
    </Container>
  );
};

const mapStateToProps = state => ({
  memberships: state.organization.selected.memberships || [],
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(MembershipAndBillingStatus);
