import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: absolute;
  top: 510px;
  width: 220px;
  height: 49px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 10px;
  z-index: 1000000;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
`;

export const PartyIcon = styled.div`
  position: absolute;
  width: 34px;
  height: 28px;
`;

export const TextContainer = styled.div`
  margin-left: 50px;
`;

export const Title = styled.div`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #2b2b2b;
`;

export const Subtitle = styled.div`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #474747;
  margin-top: 4px;
`;

export const CancelButton = styled.button`
  position: absolute;
  width: 18px;
  height: 18px;
  top: -15px;
  right: -15px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
  transition: all 0.3s ease-in;
  &:hover {
    color: silver;
  }
`;
