import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px 25px;
  height: calc(100vh - 100px);
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const Title = styled.button`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 0;
  margin-right: 6px;
  border-radius: 2px;
  text-align: center;
  width: 130px;
  color: white;
  background-color: #007ade;
  border: 1px solid #007ade;
`;

export const Group = styled.div`
  display: flex;
  gap: 15px;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 12px;
  justify-content: center;
`;

export const MainText = styled.span`
  font-size: 12px;
`;

export const Text = styled.span`
  font-size: 10px;
  display: flex;
  align-items: center;
  line-height: 17px;
  gap: 4px;
`;

export const Button = styled.button`
  color: white;
  font-size: 14px;
  padding: 3px 10px;
  border: 1px solid ${props => props.color};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  line-height: 14px;
  transition: all 0.3s ease-in;
  background-color: ${props => props.color};
  &:hover {
    color: ${props => props.color};
    font-weight: bold;
    cursor: pointer;
    background-color: white;
    border: 1px solid ${props => props.color};
  }
  &:disabled {
    background-color: white;
    font-weight: normal;
    color: grey;
    cursor: auto;
    border: 1px solid grey;
  }
`;

export const Tab = styled.button`
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  padding: 3px 12px;
  margin-right: 6px;
  border-radius: 2px;
  text-align: center;
  color: #007ade;
  border: 1px solid #509ee5;
  transition: all 0.3s ease-in;
  background-color: #ebf0f5;
  max-height: 32px;
  &:hover,
  &.active {
    color: white;
    background-color: #007ade;
    border: 1px solid #007ade;
  }
`;
