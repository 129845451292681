import React from 'react';
import { useSelector } from 'react-redux';
import { selectedOrganizationSelector } from '../../../redux/selector';
import { TextContainer, BoldText } from './EmptyConversation.styled';
import { formatPhoneNumber } from '../../../Component/Analytics/utils';

const RepliedStopMessage = ({ newContact }) => {
  const orgPhone = useSelector(selectedOrganizationSelector).fromPhone;

  return (
    <TextContainer>
      Sorry, a new conversation couldn&apos;t be started with{' '}
      {newContact.firstName} {newContact.lastName} for the following reason:
      <BoldText>
        This contact has replied STOP and will not receive texts from{' '}
        {formatPhoneNumber(orgPhone)}. For compliance, the following
        introductory message will be sent a moment before your first text
        delivers:
      </BoldText>
      You&apos;ll need the contact to text START to{' '}
      {formatPhoneNumber(orgPhone)} in order to start a text conversation with
      them.
    </TextContainer>
  );
};

export default RepliedStopMessage;
