import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import {
  Announcement,
  Title,
  VideoContainer,
  Description,
  Line,
  Button,
  Row,
} from './SavedRowsDialogBox.styled';
import { ReactComponent as PartyIconSvg } from '../SavedRowsAnnouncement/party-icon.svg';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: '750px',
    height: '650px',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
}));

const SavedRowsDialogBox = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
      <Announcement>Announcement</Announcement>
      <VideoContainer>
        <iframe
          title="Saved Rows"
          src="https://www.loom.com/embed/fa7f7ca59b93454bb6a327fdae7f05f7?sid=d71eefb7-0243-46f3-ba6a-669aeb6f7750"
          frameBorder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
          width="100%"
        />
      </VideoContainer>
      <Row>
        <PartyIconSvg />
        <Title>Introducing: Saved Rows!</Title>
        <PartyIconSvg />
      </Row>
      <Description>
        It’s finally here: you can now save, organize, and reuse your favorite
        sections from a draft or template. Even better, we’re releasing
        collections of pre-made rows for you to try using, including useful
        content rows, industry news rows, and more. Watch the video above!
      </Description>
      <Line />
      <Button onClick={onClose}>
        <CheckIcon fontSize="small" /> Hooray!
      </Button>
    </Dialog>
  );
};

export default SavedRowsDialogBox;
