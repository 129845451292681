import React from 'react';
import styled from 'styled-components';
import { Button, Drawer as BaseDrawer, IconButton } from '@material-ui/core';
import {
  Close,
  Edit,
  LibraryBooks,
  People,
  Power,
  SettingsInputComposite,
} from '@material-ui/icons';

import {
  FormSection,
  TextArea as BaseTextArea,
  TextInput as BaseTextInput,
} from '../forms';

export const EditButton = styled(Button).attrs({
  variant: 'contained',
  startIcon: <Edit />,
})`
  && {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px 18px;

    &:hover {
      background-color: #eeeeee;
    }
  }

  & > .MuiButton-label {
    color: #007ade;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.25;
  }
`;

export const Drawer = styled(BaseDrawer).attrs({
  anchor: 'right',
})`
  & > .MuiDrawer-paper {
    width: 790px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
  }
`;

Drawer.Header = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 15px 25px;
  background: #005092;
`;

Drawer.CloseButton = styled(IconButton).attrs({
  size: 'small',
  children: <Close />,
})`
  color: #ffffff !important;
`;

Drawer.Title = styled.b`
  margin-right: auto;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
`;

Drawer.Body = styled.div`
  padding: 30px;
  overflow-y: auto;
`;

export const Content = styled.div`
  display: grid;
  grid-template-areas:
    'details  status'
    'audience audience'
    'stages   stages';
  grid-template-rows: repeat(3, auto);
  grid-template-columns: 500px 170px;
  justify-content: space-between;
  gap: 40px 20px;
`;

export const StatusSection = styled(FormSection).attrs({
  IconComponent: Power,
})`
  grid-area: status;
`;

export const DetailsSection = styled(FormSection).attrs({
  IconComponent: LibraryBooks,
})`
  grid-area: details;
`;

export const AudienceSection = styled(FormSection).attrs({
  IconComponent: People,
})`
  grid-area: audience;
`;

export const StagesSection = styled(FormSection).attrs({
  IconComponent: SettingsInputComposite,
})`
  grid-area: stages;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const TextDisplay = styled(BaseTextInput).attrs({ disabled: true })`
  > .MuiInputBase-root {
    font-weight: 600;
  }
`;

export const TextAreaDisplay = styled(BaseTextArea).attrs({ disabled: true })`
  > .MuiInputBase-root {
    font-weight: 500;
  }
`;

export const LoadingMessage = styled.span.attrs({
  children: 'Loading...',
})`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`;

export const ErrorMessage = styled.span.attrs({
  children:
    'Failed to load the campaign. Please try again later. If the issue persists, please contact us.',
})`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`;

export const ExportButton = styled.span`
  color: #007ade;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  font-size: 15px;
  margin-top: -5px;

  &:hover {
    text-decoration: underline;
  }
`;
