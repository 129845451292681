import styled from 'styled-components';

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 17px;
  font-family: Montserrat;
  height: 100%;
  justify-content: center;
  color: #333333;
  line-height: 22px;
`;

export const BoldText = styled.div`
  font-weight: 700;
  margin: 15px 0;
`;

export const ItalicText = styled.div`
  font-style: italic;
`;
