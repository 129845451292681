import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, TextField } from '@material-ui/core';
import { AddCircleOutline, CancelOutlined } from '@material-ui/icons';
import SelectDropDown from '../_shared/selectDropdown';
import Container from '../_shared/container';
import Text from '../_shared/text';
import Wrap from '../_shared/wrap';
import Bold from '../_shared/bold';
import Content from '../../../_shared/content';
import FitContent from '../_shared/fitContent';
import Space from '../_shared/space';
import {
  addFilter,
  removeFilter,
  toast,
} from '../../../../../../../../../redux/action';

const MembershipAndType = ({
  filterName,
  memberships,
  checked,
  selectedFilters,
  orgTypeName,
}) => {
  const dispatch = useDispatch();

  const [selectedMembership, setMembership] = useState({});
  const [type, setType] = useState('');
  const [membershipAndType, setMembershipAndType] = useState(
    selectedFilters || []
  );

  useEffect(() => {
    if (membershipAndType.length) {
      dispatch(addFilter({ [filterName]: membershipAndType }));
    } else if (
      membershipAndType.length === 0 &&
      selectedFilters &&
      selectedFilters.length !== 0
    ) {
      dispatch(removeFilter(filterName));
    }
  }, [membershipAndType]);

  useEffect(() => {
    if (!checked && membershipAndType.length) {
      dispatch(removeFilter(filterName));
    }
  }, [checked]);

  const handleMembershipChange = ({ target }) => {
    const membershipName = target.value;
    const matchingMembership = memberships.find(
      mem => mem.name.trim() === membershipName.trim()
    );
    if (!matchingMembership) {
      dispatch(toast('error', 'Error selecting Membership.'));
      return;
    }
    setMembership(matchingMembership);
  };

  const handleTypeChange = ({ target }) => {
    setType(target.value);
  };

  const handleAddMembershipAndType = () => {
    const errorMessage = [];
    if (!Object.keys(selectedMembership).length) {
      errorMessage.push('Please select Membership.');
    }
    if (!type) {
      errorMessage.push('Please add Type.');
    }
    if (errorMessage.length) {
      dispatch(toast('error', errorMessage.join('\n')));
    } else {
      setMembershipAndType([
        ...membershipAndType,
        { selectedMembership: selectedMembership.amsId, type },
      ]);
      setMembership({});
      setType('');
    }
  };

  const handleRemoveMembershipAndType = index => {
    setMembershipAndType([
      ...membershipAndType.slice(0, index),
      ...membershipAndType.slice(index + 1),
    ]);
  };

  const getAssociationOrMembership = () => {
    return orgTypeName === 'magic' ? 'Association' : 'Membership';
  };

  const getMembershipName = membershipId => {
    const matchingMembership = memberships.find(
      mem => mem.amsId === membershipId
    );
    return matchingMembership.name;
  };

  const getMembershipTypeName = () => {
    return orgTypeName === 'magic'
      ? 'plus Billing Type'
      : 'plus Membership Type';
  };

  return (
    <Container>
      {(selectedFilters || []).map(({ selectedMembership, type }, index) => (
        <Wrap key={index}>
          <FitContent>
            {index > 0 ? <Space>or</Space> : <Space>-</Space>}{' '}
            {getAssociationOrMembership()}
          </FitContent>{' '}
          <Bold>{getMembershipName(selectedMembership)}</Bold>
          <FitContent>{getMembershipTypeName()}</FitContent>
          <Bold>{type}</Bold>
          <CancelOutlined
            fontSize="small"
            style={{ fill: '#E63946' }}
            onClick={() => handleRemoveMembershipAndType(index)}
          />
        </Wrap>
      ))}
      <Content>
        <Text>
          {membershipAndType.length ? 'or' : ''} {getAssociationOrMembership()}
        </Text>
        <SelectDropDown
          value={selectedMembership.name || ''}
          menuItemNames={memberships.map(mem => mem.name)}
          onChange={handleMembershipChange}
        />
      </Content>
      <Content>
        <Text>{getMembershipTypeName()}</Text>
        <TextField
          value={type}
          onChange={handleTypeChange}
          style={{ width: 60 }}
        />
      </Content>
      <Button
        variant="contained"
        size="small"
        startIcon={<AddCircleOutline />}
        style={{
          borderRadius: 50,
          backgroundColor: '#007ADE',
          margin: '0 25px',
          padding: '5px 15px',
          color: 'white',
        }}
        onClick={handleAddMembershipAndType}
      >
        Add
      </Button>
    </Container>
  );
};

const mapStateToProps = state => ({
  memberships: state.organization.selected.memberships || [],
  orgTypeName: state.audience.orgTypeName,
});

export default connect(mapStateToProps)(MembershipAndType);
