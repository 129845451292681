import api from '../../utils/andromeda-api';

export const OrganizationType = {
  MAGIC: 'magic',
  LAMPS: 'lamps',
  GROWTH_ZONE: 'gz',
  RAMCO: 'ramco',
  TANGILLA: 'tangilla',
  OTHER: 'other',
};

export const isAmsSourcedOrg = org => {
  return org.type !== 'other';
};

export const getOrgAssociations = org => {
  return org.memberships || [];
};

export const hasOrgAssociations = org => getOrgAssociations(org).length > 0;

export async function getOrgMsgStatus(orgId) {
  try {
    const response = await api.get(`/organization/messages/progress/${orgId}`);
    return response.data.msgProgs;
  } catch (e) {
    return console.error(
      'Error retrieving org message statuses [getOrgMsgStatus]',
      e
    );
  }
}

export async function getOrgBillingReport(dueDate, invoiceDate, serviceDate) {
  try {
    const response = await api.get(
      encodeURI(
        `/organization/billingReport?dueDate=${dueDate}&invoiceDate=${invoiceDate}&serviceDate=${serviceDate}`
      )
    );
    return response.data.result;
  } catch (e) {
    return console.error(
      'Error getting billing report [getOrgBillingReport]',
      e
    );
  }
}

export async function fetchOrgTypes() {
  const response = await api.get('/organization/getOrgTypes');
  return response.data.result;
}

export async function getOrgTypes() {
  try {
    return fetchOrgTypes();
  } catch (e) {
    return console.error('Error retrieving org types [getOrgTypes]', e);
  }
}

export async function createOrganization(orgData) {
  try {
    await api.post('/organization/create', orgData);
    console.log('Added organization');
    return orgData;
  } catch (e) {
    console.error('Error adding organization [createOrganization]', e);
    return false;
  }
}

export async function updateOrganization(orgData) {
  try {
    const response = await api.post('/organization/update', orgData);
    console.log('Retrieved organization information');
    return response.data.result;
  } catch (e) {
    console.error('Error updating org [updateOrganization]', e);
    return false;
  }
}

export async function getColumnsVisibility(orgId) {
  try {
    const response = await api.get(
      `/organization/contactsColumnsVisibility/${orgId}`
    );
    console.log('Retrieved contacts columns visibility for manage screen');
    return response.data.result;
  } catch (e) {
    console.error('Error retrieving [getColumnsVisibility]', e);
    return false;
  }
}

export async function getOrganizationList() {
  try {
    const response = await api.get('/organization/list');
    console.log('Retrieved organization information');
    return response.data.result;
  } catch (e) {
    console.error('Error getting org list [getOrganizationList]', e);
    return { error: true };
  }
}

export async function getMultiUserOrgList(orgs) {
  try {
    const response = await api.get('/organization/list');
    const orgList = response.data.result;
    const orgIdList = orgs.map(org => org.orgId);
    const userOrgList = orgList.filter(org => orgIdList.includes(org.id));

    console.log('Retrieved multi organization information');
    return userOrgList;
  } catch (e) {
    console.error('Error getting org list [getMultiUserOrgList]', e);
    return { error: true };
  }
}

export async function getOrganization(orgId) {
  try {
    const response = await api.get(`/organization/${orgId}`);
    console.log('Retrieved organization information');
    let org = response.data.result;
    org.displayPhone = org.phoneNumber
      ? `(${org.phoneNumber.substring(0, 3)}) ${org.phoneNumber.substring(
          3,
          6
        )}-${org.phoneNumber.substring(6)}`
      : '';
    return org;
  } catch (e) {
    console.error('Error getting org info [getOrganization]', e);
    return { error: true };
  }
}

export async function getOrganizationForPreferencePage(orgId) {
  try {
    const response = await api.get(`/preference/organization/${orgId}`);
    console.log('Retrieved organization information');
    let org = response.data.result;
    org.displayPhone = org.phoneNumber
      ? `(${org.phoneNumber.substring(0, 3)}) ${org.phoneNumber.substring(
          3,
          6
        )}-${org.phoneNumber.substring(6)}`
      : '';
    return org;
  } catch (e) {
    console.error('Error getting org info [getOrganization]', e);
    return { error: true };
  }
}

export async function getOrgByPrefPage(acronym, hashId) {
  try {
    const response = await api.get(
      `/preference/orgPrefPage?acronym=${acronym}&hash=${hashId}`
    );
    const org = response.data.result;
    org.displayPhone = org.phoneNumber
      ? `(${org.phoneNumber.substring(0, 3)}) ${org.phoneNumber.substring(
          3,
          6
        )}-${org.phoneNumber.substring(6)}`
      : '';
    return org;
  } catch (err) {
    console.error(
      'Error getting org info by pref page [getOrgByPrefPage]',
      err
    );
    return { error: true };
  }
}

export async function getOrgOffices(orgId) {
  try {
    const response = await api.get(`/organization/offices/${orgId}`);
    console.log('Retrieved organization offices');
    return response.data.result;
  } catch (e) {
    console.error('Error getting org offices [getOrgOffices]', e);
    return { error: true };
  }
}

/******************************** CRUD of Organization end ************************* */
