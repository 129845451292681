import styled from 'styled-components';

export const Announcement = styled.div`
  fontfamily: Lato, sans-serif;
  fontstyle: normal;
  fontweight: 500;
  fontsize: 16px;
  color: #79747e;
  padding: 30px;
`;

export const Title = styled.div`
  font-family: Lato;
  font-weight: 800;
  font-size: 26px;
  letter-spacing: 0%;
  text-align: center;
  color: #0076ff;
  padding: 10px;
`;

export const VideoContainer = styled.div`
  width: 491px;
  height: 300px;
  display: flex;
`;

export const Description = styled.div`
  font-family: Lato;
  font-weight: 500;
  font-size: 16px;
  margin: 0 10%;
  color: #474747;
`;

export const Line = styled.div`
  border: 0.5px solid;
  width: 470px;
  color: #e9e9e9;
  margin: 20px 10% 30px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 129px;
  height: 35px;
  border-radius: 25px;
  gap: 8px;
  border: 2px solid #0076ff;
  background-color: #0076ff;
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
  transition: all 0.3s ease-in;

  &:hover,
  &.active {
    color: #0076ff;
    background-color: white;
    border: 1px solid #0076ff;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
