import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  gap: 18px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 14px;
`;

export const SmallText = styled.span`
  font-size: 11px;
  color: grey;
`;
