import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  PeopleAlt,
  HighlightOff,
  EnhancedEncryption,
  Autorenew,
} from '@material-ui/icons';
import { Button, CircularProgress } from '@material-ui/core';
import { loadTargetAudience } from '../../../../../redux/action';
import { getMembers } from '../../Components/_shared/getMembers';
import Stats from './Components/stats';
import FilterStats from './Components/filterStats';
import Content from '../Content/Components/_shared/content';
import { REQUIRE, EXCLUDE, ADD } from '../../constants';
import {
  Container,
  Wrapper,
  Wrap,
  Underline,
  Bold,
} from './Components/Footer.styled';

const Footer = ({
  selectedTopicsList,
  selectedGroups,
  topics,
  targetedAudienceLength,
  selectedFilters,
  selectedMembers,
  orgId,
  actions,
  withoutPadding,
}) => {
  const [selectedTopicsNames, setSelectedTopicsNames] = useState([]);
  const [requiredGroupNames, setRequiredGroupNames] = useState([]);
  const [excludedGroupNames, setExcludedGroupNames] = useState([]);
  const [additionalGroupNames, setAdditionalGroupNames] = useState([]);
  const [includedMembers, setIncludedMembers] = useState([]);
  const [excludedMembers, setExcludedMembers] = useState([]);
  const [loadingEstimates, setLoadingEstimates] = useState(false);

  useEffect(() => {
    actions.loadTargetAudience('');
  }, [orgId]);

  useEffect(() => {
    const memberNames = {
      [EXCLUDE]: [],
      [ADD]: [],
    };

    Object.keys(selectedMembers).forEach(id => {
      memberNames[selectedMembers[id].action].push(selectedMembers[id].name);
    });

    setIncludedMembers(memberNames[ADD]);
    setExcludedMembers(memberNames[EXCLUDE]);
  }, [selectedMembers]);

  useEffect(() => {
    const topicNames = [];

    selectedTopicsList.forEach(topicId => {
      const selectedTopic = topics.find(topic => topic.id === topicId);
      if (selectedTopic) {
        topicNames.push(selectedTopic.name);
      }
    });

    setSelectedTopicsNames(topicNames);
  }, [selectedTopicsList, topics]);

  useEffect(() => {
    const groupNames = {
      [REQUIRE]: [],
      [EXCLUDE]: [],
      [ADD]: [],
    };

    Object.keys(selectedGroups).forEach(id => {
      groupNames[selectedGroups[id].action].push(selectedGroups[id].name);
    });

    setRequiredGroupNames(groupNames[REQUIRE]);
    setExcludedGroupNames(groupNames[EXCLUDE]);
    setAdditionalGroupNames(groupNames[ADD]);
  }, [selectedGroups]);

  const handleRefreshEstimates = async () => {
    setLoadingEstimates(true);
    const { uniqueValuesCount } = await getMembers(
      {
        selectedTopicsList,
        selectedGroups,
        selectedMembers,
        selectedFilters,
      },
      orgId,
      { estimateCount: true }
    );
    actions.loadTargetAudience(uniqueValuesCount);
    setLoadingEstimates(false);
  };

  const PeopleIcon = <PeopleAlt style={{ margin: '0 10px' }} />;

  if (selectedTopicsList.length === 0) {
    return (
      <Container withoutPadding={withoutPadding}>
        <Wrap style={{ fontSize: 14 }}>
          {PeopleIcon} Start selecting Topics to see target audience stats
        </Wrap>
      </Container>
    );
  }

  return (
    <Container withoutPadding={withoutPadding}>
      <Wrap>
        <Content>
          {PeopleIcon}
          <b>Targeted Audience:</b>
          {loadingEstimates ? (
            <CircularProgress size={20} style={{ margin: '0 35px' }} />
          ) : (
            <Underline>{targetedAudienceLength}</Underline>
          )}
        </Content>
        <Button
          onClick={handleRefreshEstimates}
          variant="contained"
          style={{
            color: '#1B85E0',
            padding: '2px 10px',
          }}
          size="small"
          startIcon={<Autorenew />}
        >
          Refresh Estimate
        </Button>
      </Wrap>
      <Wrapper>
        <Stats
          Icon={EnhancedEncryption}
          color="#007ADE"
          text={
            <>
              Anyone who is subscribed to your <Bold>Topic(s)</Bold>
            </>
          }
          orList={selectedTopicsNames}
        />
        {requiredGroupNames.length || additionalGroupNames.length ? (
          <Stats
            Icon={EnhancedEncryption}
            color="#007ADE"
            text={
              <>
                and who belongs to the <Bold>Group</Bold>
              </>
            }
            andList={requiredGroupNames}
            orList={additionalGroupNames}
          />
        ) : (
          ''
        )}
        {Object.keys(selectedFilters).length ? (
          <FilterStats
            Icon={EnhancedEncryption}
            color="#007ADE"
            text={
              <>
                and who meets <Bold paddingRight={'5px'}>Filter</Bold> criteria
              </>
            }
            filters={selectedFilters}
          />
        ) : (
          ''
        )}
        {includedMembers.length ? (
          <Stats
            Icon={EnhancedEncryption}
            color="#007ADE"
            text={
              <>
                and specifically include <Bold>Contacts</Bold>
              </>
            }
            orList={includedMembers}
          />
        ) : (
          ''
        )}
        {excludedGroupNames.length ? (
          <Stats
            Icon={HighlightOff}
            color="#E63946"
            backColor="#EEE6E6"
            text={
              <>
                but exclude anyone who belongs the <Bold>Group</Bold>
              </>
            }
            orList={excludedGroupNames}
          />
        ) : (
          ''
        )}
        {excludedMembers.length ? (
          <Stats
            Icon={HighlightOff}
            color="#E63946"
            backColor="#EEE6E6"
            text={
              <>
                and specifically exclude <Bold>Contacts</Bold>
              </>
            }
            andList={excludedMembers}
          />
        ) : (
          ''
        )}
      </Wrapper>
    </Container>
  );
};

const mapStateToProps = state => ({
  selectedTopicsList: state.audience.selectedTopicsList,
  selectedGroups: state.audience.selectedGroups,
  selectedMembers: state.audience.selectedMembers,
  selectedFilters: state.audience.selectedFilters,
  topics: state.topics,
  targetedAudienceLength: state.audience.targetedAudienceLength,
  orgId: state.organization.selected.id,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loadTargetAudience }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
